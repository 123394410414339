import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { colors, distances, border } from '../../../../styles/constants';
import { H2, P } from '../../../../components/Typography';
import { ButtonLink } from '../../../../components/Buttons';
import FinishAccountIcon from '../FinishAccountIcon';

export interface TransactionDetailsProps extends WithTranslation {
    accountId: string;
}

const TransactionDetails = (
    {
        t,
        accountId,
    }: TransactionDetailsProps
) => {
    return (
        <Wrapper>
            <MarginWrapper>
                <FinishAccountIcon height="200px" />
            </MarginWrapper>
            <MarginH2>{t('settings.account.test_mode_box.title')}</MarginH2>
            <MarginP>{t('settings.account.test_mode_box.description')}</MarginP>
            <ButtonLink to={`/T${accountId}/settings/account/edit`}>
                {t('settings.account.test_mode_box.call_to_action')}
            </ButtonLink>
        </Wrapper>
    );
};

export default withTranslation()(TransactionDetails);

const Wrapper = styled.div`
    position: relative;
    background: ${colors.background};
    padding: ${distances.large};
    padding-top: ${distances.normal};
    text-align: center;
`;

const MarginWrapper = styled.div`
    margin-bottom: ${distances.small};
`;

const MarginH2 = styled(H2)`
    margin-bottom: ${distances.normal};
`;

const MarginP = styled(P)`
    margin-bottom: ${distances.normal};
    line-height: 20px;
`;
