import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Buttons';
import type { ServiceFeatureStatus } from '../../../types/management-auth/generated';

type DailyPayoutsToggleButtonProps = {
    status?: ServiceFeatureStatus;
    requested?: 'enable' | 'disable';
    onEnable: () => void;
    onDisable: () => void;
    disabled: boolean;
    active: boolean;
};


export default function DailyPayoutsToggleButton({
    status, requested, onEnable, onDisable, disabled,
    active,
}: DailyPayoutsToggleButtonProps) {

    const isEnabled = status === 'enabled' || active;

    if (status === 'declined') {
        return null;
    }

    return (
        <Button className="large outlined" onClick={isEnabled ? onDisable : onEnable} disabled={disabled || status === 'pending'}>
            <ButtonLabel status={status} requested={requested} isEnabled={isEnabled} />
        </Button>
    );
}

const ButtonLabel = (props: {
    status: string | undefined; requested: string | undefined;
    isEnabled: boolean;
}) => {
    const { t } = useTranslation();
    let label = t('settings.additional_services.daily_payouts.enable');
    if (props.isEnabled && props.status !== 'pending') {
        return <>{t('settings.additional_services.daily_payouts.turn_off')}</>;
    }
    switch (props.status) {
        case 'pending': {
            switch (props.requested) {
                case 'disable': label = t('settings.additional_services.daily_payouts.turn_off_in_progress');
                    break;
                case 'enable': label = t('settings.additional_services.daily_payouts.enable_in_progress');
                    break;
                default: break;
            }
            break;
        }
        case 'enabled': label = t('settings.additional_services.daily_payouts.turn_off');
            break;
        default: label = t('settings.additional_services.daily_payouts.enable');
            break;
    }

    return <>{label}</>;
};
