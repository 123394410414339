import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { Dd, Dl, Dt } from '../../../../components/DescriptionList/Horizontal';
import { DateTime } from '../../../../components/Formatters';
import { H2, P } from '../../../../components/Typography';
import View from '../../../../components/View';
import { distances } from '../../../../styles/constants';
import { CheckoutConfig } from '../../../../types/checkout';
import { CheckoutConfigWrite } from '../../../../types/checkout/generated';
import { Account } from '../../../../types/management-auth';
import { PayExConnection, PayExConnectionV2 } from '../../../../types/management-auth/generated';
import { getPartnerConfiguration, legacyPayexDefaults } from '../../common/partnerConfiguration';
import { ActivePaymentOptions } from './ActivePaymentOptions';
import { PaymentOptionsDialog } from './PaymentOptionsDialog';
import {
    ConnectionAside,
    ConnectionsStatus,
    ConnectionText,
    Details,
    Flex,
    PaymentConnection,
    ToggleDetails
} from './styledListItemComponents';
import { useActions } from '../../../../Actions';

const getPayexLink = (accountId: string, country: string) => {
    return (
        getPartnerConfiguration(accountId, country)?.payment_product_types['payex.creditcard']?.link ||
        legacyPayexDefaults[country] ||
        ''
    );
};

export interface PayexConnectionListItemProps extends WithTranslation {
    openPayExCallbackModal: (accountId: string, account: Account, type?: 'swap_agreement' | 'initial_connection_configuration') => void;
    payex: PayExConnection | PayExConnectionV2 | undefined;
    disabled: boolean;
    account: Account;
    accountId: string;
    checkoutConfig: CheckoutConfig | undefined;
    updateCheckoutConfig: (accountId: string, checkoutConfig: CheckoutConfigWrite) => Promise<void>;
    isUpdatedSuccessfully: boolean;
}

const availablePaymentOptions: CheckoutConfigWrite['configuration']['payment_options'] = [
    { type: 'payex.applepay', currencies: ['NOK', 'SEK', 'EUR', 'DKK']},
    { type: 'payex.googlepay', currencies: ['NOK', 'SEK', 'EUR', 'DKK']},
    { type: 'payex.clicktopay', currencies: ['NOK', 'SEK', 'EUR', 'DKK']},
    { type: 'payex.creditcard', currencies: ['NOK', 'SEK', 'EUR', 'DKK']},
    { type: 'payex.vipps', currencies: ['NOK']},
    { type: 'payex.swish', currencies: ['SEK']},
    { type: 'payex.mobilepay', currencies: ['DKK']},
];

const PayexConnectionListItem = (
    {
        t,
        payex,
        openPayExCallbackModal,
        disabled,
        account,
        accountId,
        checkoutConfig,
        isUpdatedSuccessfully,
        updateCheckoutConfig,
    }: PayexConnectionListItemProps
) => {
    const { openPayExModal } = useActions('connections.payex');
    const [payexDetails, setPayexDetails] = useState(false);
    const [showPaymentOptionsDialog, setShowPaymentOptionsDialog] = useState(false);
    const payexLink = getPayexLink(accountId, account.company?.address?.country);

    useEffect(() => {
        if (isUpdatedSuccessfully) {
            setShowPaymentOptionsDialog(false);
        }
    }, [isUpdatedSuccessfully]);

    return (
        <PaymentConnection disabled={disabled}>
            <View alignItems="flex-start" gap={distances.small}>
                <H2>{t(`settings.payment_connections.payex.name`)}</H2>
                {payex && payex.status && (
                    <ConnectionsStatus status={payex.status}>
                        {t(`settings.payment_connections.status.${payex.status}`)}
                    </ConnectionsStatus>
                )}
            </View>
            <Flex>
                <ConnectionText>
                    <P>{t(`settings.payment_connections.payex.description`)}</P>
                </ConnectionText>
                <ConnectionAside>
                    {!payex && (
                        <Button
                            disabled={disabled}
                            onClick={() => openPayExModal(account)}
                            className="stretch"
                        >
                            {t(`settings.payment_connections.payex.call_to_action`)}
                        </Button>
                    )}
                    {payex && !(payex.signup && payex.signup.callback_at) && (
                        <Group>
                            <Button onClick={() => openPayExCallbackModal(accountId, account, 'initial_connection_configuration')} className="alt">
                                {t(`settings.payment_connections.payex.complete_callback`)}
                            </Button>
                        </Group>
                    )}
                </ConnectionAside>
            </Flex>
            {payex && (
                <>
                    <ToggleDetails onClick={() => setPayexDetails(!payexDetails)}>
                        {payexDetails
                            ? t('settings.payment_connections.collapse_details')
                            : t('settings.payment_connections.expand_details')}
                    </ToggleDetails>
                    <Details className={payexDetails ? 'open' : 'closed'}>
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.signup_url')}</Dt>
                            <Dd>
                                {payexLink && (
                                    <a href={payexLink} target="_blank" rel="noopener noreferrer">
                                        {payexLink}
                                    </a>
                                )}
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.payment_options')}</Dt>
                            <Dd>
                                {(payex?.signup?.payment_options || [])
                                    .map((option) => t(`payments.product_types.${option.type}`))
                                    .join(', ')}
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.created_at')}</Dt>
                            <Dd>
                                <DateTime isoString={payex.created_at || ''} />
                            </Dd>
                        </Dl>
                        {payex.signup && payex.signup.callback_at && (
                            <>
                                <Dl>
                                    <Dt doubleColumn>{t('settings.payment_connections.fields.callback_at')}</Dt>
                                    <Dd>
                                        {payex.signup.callback_at ? (
                                            <DateTime isoString={payex.signup.callback_at} />
                                        ) : (
                                            <em>{t('settings.payment_connections.fields.no_callback_yet')}</em>
                                        )}
                                    </Dd>
                                </Dl>

                                {checkoutConfig && <ActivePaymentOptions checkoutConfig={checkoutConfig} paymentType="payex" />}
                                {checkoutConfig &&
                                    <ButtonGroup>
                                        <Button
                                            type="button"
                                            className="alt"
                                            onClick={() => setShowPaymentOptionsDialog(true)}>
                                            {t('settings.payment_connections.edit_payment_options_button')}
                                        </Button>
                                        <Button
                                            type="button"
                                            className="alt"
                                            onClick={() => openPayExCallbackModal(accountId, account, 'swap_agreement')}>
                                            {t('settings.payment_connections.payex.add_new_agreement')}
                                        </Button>
                                        <PaymentOptionsDialog
                                            availablePaymentOptions={availablePaymentOptions}
                                            isDialogOpen={showPaymentOptionsDialog}
                                            handleCloseDialog={() => setShowPaymentOptionsDialog(false)}
                                            handleSavePaymentOptions={(newCheckoutConfiguration) => updateCheckoutConfig(accountId, newCheckoutConfiguration)}
                                            checkoutConfig={checkoutConfig}
                                        />
                                    </ButtonGroup>
                                }
                            </>
                        )}
                    </Details>
                </>
            )}
        </PaymentConnection>
    );
};

const Group = styled.div`
    display: flex;
    gap: 8px;
`;

export default PayexConnectionListItem;
