import * as React from 'react';
import styled from 'styled-components/macro';

import { border, colors, defaultRadius, distances, palette } from '../../styles/constants';
import { InputValidation } from './validation';

import { useTranslation } from 'react-i18next';
import { Label } from '../Typography';

export type OnChangeFunction = (event: Event) => void;

export interface InputProps {
    name: string;
    label?: any;
    placeholder: string;
    value: string;
    onChange: (e: React.FormEvent<HTMLInputElement> & React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement> & React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    type?: string;
    validation?: InputValidation;
    rightAligned?: boolean;
    prefix?: string;
    postfix?: string;
    leftIcon?: React.ReactNode;
    autoComplete?: string;
    maxlength?: number;
    className?: string;
    autoFocus?: boolean;
    [key: string]: any;
    readOnly?: boolean;
    required?: boolean;
    mb?: string;
}

const Input = (props: InputProps) => {
    let input: any = null;
    const { t } = useTranslation();

    const whitelistedHtmlProps = ['aria-autocomplete', 'aria-activedescendant', 'aria-controls', 'aria-labelledby'];

    interface AnyObject {
        [key: string]: any;
    }

    const otherProps = Object.keys(props)
        .filter((key) => whitelistedHtmlProps.includes(key))
        .reduce((obj: AnyObject, key: string) => {
            obj[key] = props[key];
            return obj;
        }, {});

    const labelExists = typeof props.label === 'string' ? props.label.length > 0 : !!props.label;

    return (
        <Wrapper className={props.className}>
            {props.label && <Label htmlFor={props.name}>{props.label}{props.required ? '' : t('common.optional')}</Label>}
            <InputElement
                {...otherProps}
                autoComplete={props.autoComplete}
                autoFocus={props.autoFocus}
                rightAligned={props.rightAligned || false}
                value={props.value}
                id={props.name}
                type={props.type || 'text'}
                name={props.name}
                disabled={props.disabled}
                placeholder={props.placeholder}
                ref={(elem) => {
                    input = elem;
                }}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onKeyDown={props.onKeyDown}
                className={props.validation ? props.validation.state : ''}
                maxLength={props.maxlength}
                prefix={props.prefix}
                postfix={props.postfix}
                leftIcon={props.leftIcon}
                readOnly={props.readOnly}
                mb={props.mb}
            />
            {props.prefix && (
                <Prefix
                    labelExists={labelExists}
                    onClick={() => {
                        input && input.focus();
                    }}
                >
                    {props.prefix}
                </Prefix>
            )}
            {props.leftIcon && (
                <LeftIcon
                    onClick={() => {
                        input && input.focus();
                    }}
                >
                    {props.leftIcon}
                </LeftIcon>
            )}
            {props.postfix && (
                <Postfix
                    labelExists={labelExists}
                    onClick={() => {
                        input && input.focus();
                    }}
                >
                    {props.postfix}
                </Postfix>
            )}
            {props.validation && props.validation.message && (
                <Message htmlFor={props.name} className={props.validation.state} labelExists={typeof props.label === 'string' ? props.label.length > 0 : !!props.label}>
                    {props.validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    &.stretch {
        width: 100%;
    }
    &.minimal {
        input {
            margin-bottom: 0;
            min-height: 0;
            padding-block: 0;
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: 0;
        }
        label {
            display: none;
        }
    }
`;

const Prefix = styled.label <{
    labelExists: boolean;
}>`
    position: absolute;
    top: 40px;
    left: ${distances.small};
    color: ${colors.textSecondary};
    cursor: text;

    ${(props) => (!props.labelExists ? 'top: 10px;' : '')}
`;
const Postfix = styled.label<{
    labelExists: boolean;
}>`
    position: absolute;
    top: 40px;
    right: ${distances.small};
    color: ${colors.textSecondary};
    cursor: text;

    ${(props) => (!props.labelExists ? 'top: 10px;' : '')}
`;
const LeftIcon = styled.label`
    position: absolute;
    top: 30px;
    left: ${distances.nano};
    color: ${palette.neutral[900]};
    fill: ${palette.neutral[900]};
    cursor: text;
`;

interface InputElementProps {
    rightAligned: boolean;
    prefix?: string;
    postfix?: string;
    leftIcon?: React.ReactNode;
    mb?: string;
}

const InputElement = styled.input<InputElementProps>`
    border: none;
    outline: none;
    border: ${border.normal} solid ${palette.neutral[400]};
    border-radius: ${defaultRadius};
    background: ${colors.background};
    padding: ${distances.tiny} ${distances.small12};
    text-align: ${(props) => (props.rightAligned ? 'right' : 'left')};
    width: 100%;
    -moz-appearance: textfield;
    margin-bottom: ${(props => props.mb || distances.normal)};
    min-height: 40px;
    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);

    ${(props) => (props.prefix ? `padding-left: calc(${distances.small} + ${props.prefix.length * 12}px);` : '')}
    ${(props) => (props.postfix ? `padding-right: calc(${distances.small} + ${props.postfix.length * 12}px);` : '')}

    ${(props) => (props.leftIcon ? `padding-left: calc(${distances.small} + 20px);` : '')}

    &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
    }

    &::placeholder {
        color: ${colors.textSecondary};
    }

    &:focus {
        outline: none;
        border: ${border.normal} solid ${palette.primary[300]};
        box-shadow: 0 0 0 3px ${palette.primary[100]};

    }
    &.invalid,
    &:focus .invalid {
        border: ${border.normal} solid ${palette.destructive[300]};
    }

    &.invalid:focus {
        box-shadow: 0 0 0 3px ${palette.destructive[100]};
    }

    &.valid,
    &:focus .valid {
        border: ${border.normal} solid ${palette.success[300]};
    }

    &.valid:focus {
        box-shadow: 0 0 0 3px ${palette.success[100]};
    }

    &:disabled {
        background: ${palette.neutral[50]};
        border: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[500]};
    }
`;

const Message = styled.label<{ labelExists: boolean }>`
    position: absolute;
    top: ${(props) => (props.labelExists ? '72px' : '33px')};
    left: 0;
    font-size: 14px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

export default Input;
