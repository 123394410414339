import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import { LoadingOverlay } from '../../../../components/Loading';
import Page from '../../../../components/Page';
import { H1 } from '../../../../components/Typography';
import { slideIn } from '../../../../styles/constants';
import { Account } from '../../../../types/management-auth';

import { useSelector } from 'react-redux';
import { useActions } from '../../../../Actions';
import * as checkoutConfigSelectors from '../../../checkoutConfigurationSelectors';
import ConnectionsList from './ConnectionsList';
import { Modals } from '../..';

export interface ConnectionsPageProps {
    getAccount: (accountId: string) => void;
    openCollectorCallbackModal: () => void;
    openInstabankModal: (accountId: string, account: Account) => void;
    openPayExCallbackModal: (accountId: string, account: Account,  type?: 'swap_agreement' | 'initial_connection_configuration') => void;
    account: Account | undefined;
    isLoading: boolean;
}

const ConnectionsPage =  ({
    openInstabankModal,
    openPayExCallbackModal,
    getAccount,
    account,
    isLoading,
}: ConnectionsPageProps) => {

    const accountIds = useAccountIds();
    const accountId = accountIds.urlAccountId;

    useEffect(() => {
        getAccount(accountId);
    }, [getAccount, accountId]);

    const { getCheckoutConfig, updateCheckoutConfig } = useActions('checkout.config');
    const { openModal: openCollectorModal } = useActions('connections.collector');
    const { openModal: openCollectorCallbackModal } = useActions('connections.collector_callback');

    useEffect(() => {
        if (account) {
            getCheckoutConfig(accountId);
        }
    }, [getCheckoutConfig, accountId, account]);

    const { checkoutConfig, isUpdatedSuccessfully } = useSelector(state => {
        return ({
            checkoutConfig: checkoutConfigSelectors.checkoutConfiguration(state, accountId),
            isUpdatedSuccessfully: checkoutConfigSelectors.updatedSuccessfully(state),
        });
    });

    const { t } = useTranslation();

    const showFinishProfile = !accountIds.prodAccountId;

    if (!account) {
        return null;
    }

    return (
        <Page
            title={
                <React.Fragment>
                    <Title>{t('settings.payment_connections.title')}</Title>
                </React.Fragment>
            }
        >
            <Wrapper>
                {account && (
                    <ConnectionsList
                        {...{
                            showFinishProfile,
                            account: account,
                            accountId,
                            openInstabankModal,
                            openPayExCallbackModal,
                            openCollectorModal,
                            openCollectorCallbackModal,
                            checkoutConfig,
                            updateCheckoutConfig,
                            isUpdatedSuccessfully,
                        }}
                    />
                )}
                {isLoading && <LoadingOverlay />}
            </Wrapper>
            <Modals />
        </Page>
    );
};

const Wrapper = styled.div`
    position: relative;
    animation: ${slideIn} 0.3s ease-in-out;
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default ConnectionsPage;
