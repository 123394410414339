import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { CORE_API_HOSTNAME } from '../../../../env';
import fulfill from '../../../../fulfill';
import type { Delivery, DeliveryDetails } from '../../../../types/webhooks';

const fetchDeliveries = async (payload: {
    accountId: string;
    hookId: string;
}): Promise<Delivery[]> => {
    return await fulfill.get({
        accountId: payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/hooks/subscriptions/${payload.hookId}/deliveries`,
        handlers: {
            200: (deliveries: Delivery[]) => deliveries,
        },
    });
};

const fetchDeliveryDetails = async (_key: `${string}-${string}-delivery-details`, options: {
    arg: {
        accountId: string;
        hookId: string;
        deliveryId: string;
    };
}): Promise<DeliveryDetails> => {
    const { accountId, hookId, deliveryId } = options.arg;
    return await fulfill.get({
        accountId: accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/hooks/subscriptions/${hookId}/deliveries/${deliveryId}`,
        handlers: {
            200: (delivery: DeliveryDetails) => delivery,
        },
    });
};

export const useDeliveries = (hookAccountId: string | undefined, hookId: string | undefined) => {
    const { data: deliveries, isLoading: isLoadingDeliveries } = useSWR(() => hookAccountId && hookId ? { key: `${hookAccountId}-${hookId}-deliveries`, accountId: hookAccountId, hookId } : null, fetchDeliveries);
    const { trigger: getDeliveryDetails, data: deliveryDetails, isMutating: isLoadingDeliveryDetails } = useSWRMutation(`${hookAccountId}-${hookId}-delivery-details`, fetchDeliveryDetails);

    return {
        deliveries: deliveries || [],
        getDeliveryDetails,
        isLoadingDeliveries,
        deliveryDetails,
        isLoadingDeliveryDetails,
    };
};
