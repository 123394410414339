import { CheckoutConfig } from '../../../types/checkout';
import { Account } from '../../../types/management-auth';
import { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';
import { PaymentMethod } from './types';

export const toLanguageLink = (url: string | undefined, language: string) => {
    if (!url) {
        return undefined;
    }
    const joiner = url.includes('?') ? '&' : '?';
    return `${url}${joiner}language=${language}`;
};

export const paymentMethods = [
    {
        value: 'creditcard',
        label: 'Visa / Mastercard',
        countries: ['*'],
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'applepay',
        label: 'Apple Pay',
        countries: ['NO', 'SE'],
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'googlepay',
        label: 'Google Pay',
        countries: [], // Disabled by default for now but can be enabled with ?showAll=true query param
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'vipps',
        label: 'Vipps',
        countries: ['NO'],
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'swish',
        label: 'Swish',
        countries: ['SE'],
        product_types: ['checkout'],
    },
    {
        value: 'mobilepay',
        label: 'MobilePay',
        countries: ['FI', 'DK'],
        product_types: ['checkout'],
    },
    {
        value: 'klarna',
        label: 'Klarna',
        countries: ['NO', 'SE'],
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'billie',
        label: 'Billie',
        countries: ['SE'], // TODO: add 'SE' when integration is approved by Klarna
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'walley',
        label: 'Walley',
        countries: ['NO', 'SE'],
        product_types: ['checkout'],
    },
];

export const getPaymentMethods = (country: string, showAll: boolean) => {
    if (showAll) {
        return paymentMethods;
    }
    return paymentMethods.filter(pm => (pm.countries.includes(country) || pm.countries.includes('*')));
};

export const getDefaultPaymentMethods = (country: string) => {
    const paymentMethods = getPaymentMethods(country, false);
    const DEFAULT_PAYMENT_METHODS = ['creditcard', 'vipps', 'swish', 'mobilepay', 'klarna', 'walley'];
    return paymentMethods.filter(pm => DEFAULT_PAYMENT_METHODS.includes(pm.value)).map(pm => pm.value as PaymentMethod);
};

export const hasAnyPaymentMethod = (checkoutConfiguration: CheckoutConfig | undefined) => {
    if (!checkoutConfiguration) {
        return false;
    }
    return (checkoutConfiguration?.configuration?.payment_options?.length || 0) > 0;
};

export const hasAnyConnection = (account: Account | undefined) => {
    if (!account) {
        return false;
    }
    return Object.keys(account?.connections || {}).length  > 0;
};

export const checkShowApprovalsStatus = (approvals: ApprovalsPaymentResponse[], checkoutConfiguration: CheckoutConfig | undefined, account: Account | undefined) => {
    // Hide approvals status if user has no approvals and the user already has payment methods or connections configured.
    const noApprovals = approvals.length === 0;
    const approvalIsActive = approvals.some(a => a.case_status === 'ACTIVE');
    const shouldHide = (
        (
            noApprovals && (hasAnyPaymentMethod(checkoutConfiguration) || hasAnyConnection(account))
        ) || approvalIsActive
    );

    return !shouldHide;
};


export const hasAnyApprovals = (approvals: ApprovalsPaymentResponse[]) => {
    return (approvals || []).length > 0;
};
