import { Redirect, Route } from 'react-router-dom';

import styled from 'styled-components/macro';
import { useAccountId } from '../auth/useAccountId';
import { WithNotFound } from '../errors';
import AccountRoutes from './account/Routes';
import AdditionalServicesRoutes from './additionalServices/Routes';
import { Routes as ClientRoutes } from './clients';
import HooksRoutes from './hooks/Routes';
import { Routes as LocationsRoutes } from './locations';
import { Routes as PaymentConnectionRoutes } from './paymentConnections';
import { Routes as PaymentProfileRoutes } from './paymentProfiles';
import PayoutDestinationRoutes from './payoutDestinations/Routes';
import { Routes as PayoutRulesRoutes } from './payoutRules';
import { Routes as ReportConfigRoutes } from './reports';
import { Routes as SettlementReportsRoutes } from './settlementReports';
import { Routes as ThirdPartyIntegrationsRoutes } from './thirdParty';
import UserRoutes from './users/Routes';

const SettingsRoutes = () => {
    const accountId = useAccountId();
    const isProd = accountId?.startsWith('P');
    return (
        <Wrapper>
            <WithNotFound>
                <Route
                    exact
                    path="/:accountId/settings"
                    render={(props) => <Redirect to={`/${props.match.params.accountId}/settings/account`} />}
                />
                {UserRoutes}
                {ClientRoutes}
                {HooksRoutes}
                {AccountRoutes}
                {PaymentProfileRoutes}
                {PaymentConnectionRoutes}
                {PayoutRulesRoutes}
                {PayoutDestinationRoutes}
                {LocationsRoutes}
                {ReportConfigRoutes}
                {isProd && SettlementReportsRoutes}
                {ThirdPartyIntegrationsRoutes}
                {AdditionalServicesRoutes}
            </WithNotFound>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export default SettingsRoutes;
