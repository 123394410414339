import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Button, ButtonGroup } from '../../../../components/Buttons';
import Card from '../../../../components/Card';
import { BackButton, Checkbox, Input, ValidationState } from '../../../../components/Forms';
import Icon from '../../../../components/Icons';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Dismiss, PageButton } from '../../../../components/Page';
import { H1, H2 } from '../../../../components/Typography';
import { colors, distances, globalColumnMaxWidth, grid } from '../../../../styles/constants';

import useAuth from '../../../../auth/accessToken/hooks/useAuth';
import { useAccountId } from '../../../../auth/useAccountId';
import Hr from '../../../../components/Hr';
import { Subscription } from '../../../../types/webhooks';
import { useCreateHook } from '../hooks/useCreateHook';

const urlRegex = new RegExp(
    /^(https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
);

const validateURL = (url: string) => {
    return urlRegex.test(url);
};
interface FormikErrors {
    url?: string;
}

// TODO: list of event depends on access for the user?
enum Events {
    account_add = 'account_add',
    account_update = 'account_update',
    approval_payout_destination_delete = 'approval_payout_destination_delete',
    approval_payout_destination_update = 'approval_payout_destination_update',
    cdd_case_update = 'cdd_case_update',
    checkout_transaction = 'checkout_transaction',
    customer_add = 'customer_add',
    customer_change_password = 'customer_change_password',
    customer_delete = 'customer_delete',
    customer_update = 'customer_update',
    discount_add = 'discount_add',
    discount_add_customers = 'discount_add_customers',
    discount_customer_update = 'discount_customer_update',
    discount_delete = 'discount_delete',
    discount_update = 'discount_update',
    location_add = 'location_add',
    location_delete = 'location_delete',
    location_update = 'location_update',
    receipt_add = 'receipt_add',
    receipt_update = 'receipt_update',
    token_add = 'token_add',
    token_remove = 'token_remove',
    wallet_transaction = 'wallet_transaction',
    shopping_draft_add = 'shopping_draft_add',
    shopping_draft_update = 'shopping_draft_update',
    shopping_draft_complete = 'shopping_draft_complete',
    shopping_order_update = 'shopping_order_update',
    shopping_order_delete = 'shopping_order_delete',
    shopping_order_event_add = 'shopping_order_event_add',
    settlement_add = 'settlement_add',
    transaction = 'transaction',
}

interface FormValues {
    url: string;
    events: string[];
    active: boolean;
    secret: string;
    accountId: string;
    [key: string]: any;
}

export const NewHookForm = () => {
    const { t } = useTranslation();
    const accountUser = useAuth(state => state.account_user);
    const accountId = useAccountId();
    const { isLoading, hook, createHook } = useCreateHook();
    if (hook) {
        return <Redirect to={`/${accountId}/settings/hooks/${hook.account_id}/${hook.id}`} />;
    }

    const currentAccount = accountUser.accounts.find(account => account.account_id === accountId);
    if (!currentAccount) {
        return null;
    }
    const initialFormValues: FormValues = {
        url: '',
        events: [],
        active: true,
        secret: '',
        accountId,
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validate={values => {
                const errors: FormikErrors = {};

                if (!values.url) {
                    errors.url = t('settings.new_hook.validation.url_required');
                }
                if (values.url && !validateURL(values.url)) {
                    errors.url = t('settings.new_hook.validation.url_invalid');
                }

                return errors;
            }}
            validateOnChange={false}
            onSubmit={values => {
                const secret =
            values.secret && values.secret.length > 0
                ? { type: 'HMAC-SHA1', value: values.secret }
                : undefined;
                const newSubscription: Subscription = {
                    config: {
                        url: values.url,
                        secret,
                        'content-type': 'application/json',
                        insecure_ssl: 0,
                    },
                    events: values.events,
                    active: values.active,
                };
                createHook(newSubscription);
            }}
        >
            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit} autoComplete="nope">
                    <Page
                        title={
                            <React.Fragment>
                                <Dismiss />
                                <Title>{t('settings.new_hook.title')}</Title>
                                <PageButton
                                    disabled={
                                        values.url === '' || errors.url !== undefined || values.events.length === 0
                                    }
                                >
                                    <Icon icon="save" fill="currentColor" />
                                    {t('settings.new_hook.create_hook')}
                                </PageButton>
                            </React.Fragment>
                        }
                    >
                        <Card title={undefined}>
                            <Wrapper>
                                <FormGroup>
                                    <H2>{t('settings.new_hook.account')}</H2>
                                    <Hr />

                                    <Flex>
                                        <InputWrapper>
                                            <Input
                                                label={t('settings.new_hook.fields.url')}
                                                placeholder={`https://example.com/postreceive`}
                                                type="url"
                                                name="url"
                                                value={values.url}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                validation={{
                                                    state: errors.url
                                                        ? ValidationState.Invalid
                                                        : ValidationState.Pristine,
                                                    message: errors.url,
                                                }}
                                                required
                                            />
                                        </InputWrapper>
                                        <InputWrapper>
                                            <Input
                                                label={t('settings.new_hook.fields.secret')}
                                                placeholder=""
                                                type="text"
                                                name="secret"
                                                value={values.secret}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputWrapper>
                                    </Flex>

                                    <ActiveCheck>
                                        <Checkbox
                                            name="active"
                                            label={t('settings.new_hook.fields.active')}
                                            checked={values.active}
                                            disabled={true}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </ActiveCheck>
                                </FormGroup>

                                <H2>{t('settings.new_hook.events')}</H2>
                                <Hr />
                                {Object.keys(Events).map(event => (
                                    <ScopeWrapper key={event}>
                                        <Checkbox
                                            name="events"
                                            label={<code>{event}</code>}
                                            value={event}
                                            checked={values.events.includes(event)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </ScopeWrapper>
                                ))}
                                <ButtonGroup>
                                    <BackButton children={t('settings.new_hook.abort')} />
                                    <Button
                                        disabled={
                                            values.url === '' ||
                                    errors.url !== undefined ||
                                    values.events.length === 0
                                        }
                                    >
                                        {t('settings.new_hook.create_hook')}
                                    </Button>
                                </ButtonGroup>
                                {isLoading && <LoadingOverlay />}
                            </Wrapper>
                        </Card>
                    </Page>
                </Form>
            )}
        </Formik>
    );
};

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const ActiveCheck = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};
    padding-top: ${distances.large40};
    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
        padding-top: ${distances.small};
        padding-bottom: ${distances.small};
    }
`;

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;



const ScopeWrapper = styled.div`
    padding-bottom: ${distances.small};
`;
