
import { Subscription, type Delivery, type DeliveryDetails } from '../../../../types/webhooks';
import DeliveryRow from './DeliveryRow';

export interface DeliveriesTableProps {
    hook: Subscription;
    deliveries: Delivery[];
    deliveryDetails: DeliveryDetails | undefined;
    getDeliveryDetails: (params: {
        accountId: string;
        hookId: string;
        deliveryId: string;
    }) => void;
}

const DeliveryTable = (
    {
        hook,
        deliveries,
        deliveryDetails,
        getDeliveryDetails,
    }: DeliveriesTableProps
) => {
    return (<div>
        {deliveries.length > 0 && (
            <>
                {deliveries.map(delivery => [
                    <DeliveryRow
                        key={delivery.id}
                        hook={hook}
                        delivery={delivery}
                        deliveryDetails={deliveryDetails}
                        getDeliveryDetails={getDeliveryDetails}
                    />,
                ])}
            </>
        )}
    </div>);
};

export default DeliveryTable;
