import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { Button } from '../../../../components/Buttons';
import { Dd, Dl, Dt } from '../../../../components/DescriptionList/Horizontal';
import { DateTime } from '../../../../components/Formatters';
import { H2, P } from '../../../../components/Typography';
import View from '../../../../components/View';
import { distances } from '../../../../styles/constants';
import { Account } from '../../../../types/management-auth';
import { InstabankConnection } from '../../../../types/management-auth/generated';
import {
    ConnectionAside,
    ConnectionsStatus,
    ConnectionText,
    Details,
    Flex,
    PaymentConnection,
    ToggleDetails
} from './styledListItemComponents';

export interface InstabankConnectionListItemProps extends WithTranslation {
    openInstabankModal: (accountId: string, account: Account) => void;
    instabank: InstabankConnection | undefined;
    disabled: boolean;
    account: Account;
    accountId: string;
}

const InstabankConnectionListItem = (
    {
        t,
        instabank,
        openInstabankModal,
        disabled,
        account,
        accountId,
    }: InstabankConnectionListItemProps
) => {
    const [instabankDetails, setInstabankDetails] = useState(false);

    return (
        <PaymentConnection disabled={disabled}>
            <View alignItems="flex-start" gap={distances.small}>
                <H2>{t(`settings.payment_connections.instabank.name`)}</H2>
                {instabank && instabank.status && (
                    <ConnectionsStatus status={instabank.status}>
                        {t(`settings.payment_connections.status.${instabank.status}`)}
                    </ConnectionsStatus>
                )}
            </View>
            <Flex>
                <ConnectionText>
                    <P>{t(`settings.payment_connections.instabank.description`)}</P>
                </ConnectionText>
                <ConnectionAside>
                    {!instabank && (
                        <Button
                            disabled={disabled}
                            onClick={() => openInstabankModal(accountId, account)}
                            className="stretch"
                        >
                            {t(`settings.payment_connections.instabank.call_to_action`)}
                        </Button>
                    )}
                </ConnectionAside>
            </Flex>
            {instabank && (
                <>
                    <ToggleDetails onClick={() => setInstabankDetails(!instabankDetails)}>
                        {instabankDetails
                            ? t('settings.payment_connections.collapse_details')
                            : t('settings.payment_connections.expand_details')}
                    </ToggleDetails>
                    <Details className={instabankDetails ? 'open' : 'closed'}>
                        {instabank.signup?.account_number && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.account_number')}</Dt>
                            <Dd>{instabank.signup.account_number}</Dd>
                        </Dl>}
                        {instabank.signup?.payment_options && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.payment_options')}</Dt>
                            <Dd>
                                {instabank.signup.payment_options
                                    .map((option) => t(`payments.product_types.${option.type}`))
                                    .join(', ')}
                            </Dd>
                        </Dl>}
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.created_at')}</Dt>
                            <Dd>
                                <DateTime isoString={instabank.created_at || ''} />
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.callback_at')}</Dt>
                            <Dd>
                                {instabank.signup?.callback_at ? (
                                    <DateTime isoString={instabank.signup.callback_at} />
                                ) : (
                                    <em>{t('settings.payment_connections.fields.no_callback_yet')}</em>
                                )}
                            </Dd>
                        </Dl>
                    </Details>
                </>
            )}
        </PaymentConnection>
    );
};

export default InstabankConnectionListItem;
