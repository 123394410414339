import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Button, ButtonGroup } from '../../../components/Buttons';
import { Input, ValidationState } from '../../../components/Forms';
import Modal from '../../../components/Modal';
import { H2, P } from '../../../components/Typography';
import View from '../../../components/View';
import { getValueAt } from '../../../helpers/getValueAt';
import ExclamationIconSvg from '../../../payout/admin/bankfile/icons/ExclamationIcon.svg';
import type { PayoutDestinationConfig } from '../../../payout/types';
import { distances, palette } from '../../../styles/constants';
import { LoadingOverlay } from '../../../components/Loading';

type DeletePayoutDestinationDialogProps = {
    opened: boolean;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    payoutDestination: PayoutDestinationConfig | undefined;
};

type ValidatedInputProps = {
    values: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    setFieldValue?: (field: string, value: string) => void;
    setTouched?: (fields: any) => void;
    initialValues?: any;
    label?: React.ReactNode;
    placeholder?: string;
    readonly?: boolean;
}

const ValidatedInput = (
    {
        values,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
        setFieldValue,
        setTouched,
        initialValues,
        label,
        placeholder,
        readonly,
    }: ValidatedInputProps
) => {
    const value = getValueAt(path, values);
    const error = getValueAt(path, errors);
    const wasTouched = getValueAt(path, touched);
    const validation = error
        ? {
            state: ValidationState.Invalid,
            message: error,
        }
        : undefined;
    return (
        <Input
            name={path}
            label={label}
            type="text"
            className="stretch"
            value={value || ''}
            onChange={e => {
                onChange(e);
            }}
            onKeyDown={e => {
                onBlur(e);
            }}
            onBlur={e => {
                onChange(e);
                onBlur(e);
            }}
            validation={wasTouched && validation}
            required={required}
            placeholder={placeholder || ''}
            readOnly={readonly}
        />
    );
};

export const DeletePayoutDestinationDialog = ({ opened, payoutDestination, onConfirm, onCancel }: DeletePayoutDestinationDialogProps) => {
    const { t } = useTranslation('payout');
    if (!opened || !payoutDestination) {
        return null;
    }
    const confirmationMessage = t('payout_destination.delete_dialog.confirmation_message', {
        name: payoutDestination.name,
    });
    return <Modal
        wrapperStyle={{
            minWidth: '200px',
            maxWidth: '600px',
            borderRadius: '10px',
        }}
    >
        <Formik
            initialValues={{
                confirmation: '',
            }}
            validate={values => {
                const errors: Record<string, string> = {};
                if (!values.confirmation) {
                    errors.confirmation = t('payout_destination.delete_dialog.errors.required');
                } else if (values.confirmation !== confirmationMessage) {
                    errors.confirmation = t('payout_destination.delete_dialog.errors.invalid');
                }
                return errors;
            }}
            onSubmit={onConfirm}
        >
            {({ values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting }) => (
                <Wrapper onSubmit={handleSubmit} data-submitting={isSubmitting}>
                    <View gap={distances.tiny} alignItems="flex-start" direction="column" width="100%">
                        <IconWrapper>
                            <ExclamationIconSvg />
                        </IconWrapper>
                        <H2>
                            {t('payout_destination.delete_dialog.title', {
                                name: payoutDestination.name,
                            })}
                        </H2>
                        <P style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-wrap',
                        }}>
                            {t('payout_destination.delete_dialog.message')}
                        </P>
                    </View>
                    <ValidatedInput
                        path="confirmation"
                        label={<Trans ns="payout" i18nKey="payout_destination.delete_dialog.confirmation" values={{ message: confirmationMessage }}>
                            <strong />
                        </Trans>}
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        errors={errors}
                        touched={touched}
                        readonly={isSubmitting}
                    />
                    <ButtonGroup>
                        <Button
                            onClick={onCancel}
                            type="button"
                            className="alt"
                            disabled={isSubmitting}
                        >
                            {t('payout_destination.delete_dialog.cancel')}
                        </Button>
                        <Button
                            type="submit"
                            className="red"
                            disabled={isSubmitting || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
                        >
                            {t('payout_destination.delete_dialog.delete', {
                                name: payoutDestination.name,
                            })}
                        </Button>
                    </ButtonGroup>
                    {isSubmitting && <LoadingOverlay />}
                </Wrapper>
            )}
        </Formik>
    </Modal>;
};

const Wrapper = styled.form`
    display: flex;
    gap: ${distances.small};
    flex-direction: column;
    align-items: flex-start;
    &[data-submitting='true'] {
        pointer-events: none;
        opacity: 0.5;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: ${palette.destructive[100]};
    svg {
        color: ${palette.destructive[500]};
        width: 30px;
        height: 30px;
    }
`;
