import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import Card from '../../../../components/Card';
import Icon from '../../../../components/Icons';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Back, PageButton } from '../../../../components/Page';
import { H1 } from '../../../../components/Typography';
import View from '../../../../components/View';
import { colors, distances } from '../../../../styles/constants';
import { useDeliveries } from '../hooks/useDeliveries';
import { useHookDetails } from '../hooks/useHookDetails';
import DeleteHookModal from './DeleteHookModal';
import Details from './Details';
import NotFound from './NotFound';
import PingHookModal from './PingHookModal';

export const HookPage = () => {
    const { accountId, hookAccountId, hookId } = useParams();
    const { t } = useTranslation();
    const { hook, pingHook, deleteHook, isDeleted, isLoading } = useHookDetails(hookAccountId, hookId);
    const { deliveries, deliveryDetails, getDeliveryDetails } = useDeliveries(hookAccountId, hookId);
    const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);
    const [isShowingPingModal, setIsShowingPingModal] = useState(false);

    if (isDeleted) {
        return <Redirect to={`/${accountId}/settings/hooks`} />;
    }

    const openDeleteHookModal = () => {
        setIsShowingDeleteModal(true);
    };

    const openPingHookModal = () => {
        setIsShowingPingModal(true);
    };

    const dismissDeleteHookModal = () => {
        setIsShowingDeleteModal(false);
    };

    const dismissPingHookModal = () => {
        setIsShowingPingModal(false);
    };

    return (
        <Page
            title={
                <React.Fragment>
                    <Back />
                    <Title>{t('settings.hook.title')}</Title>
                    <View ml="auto" gap={16}>
                        <PageButton
                            onClick={openDeleteHookModal}
                            disabled={!hook || hook.deleted_at !== undefined}
                        >
                            <Icon icon="delete" />
                            {t('settings.hook.delete_hook')}
                        </PageButton>
                    </View>
                </React.Fragment>
            }
        >
            <Card title={undefined} noPadding>
                <Wrapper>
                    {!isLoading && !hook && <NotFound hookId={hookId} accountId={accountId} />}
                    {hook && (
                        <Details
                            hook={hook}
                            openPingHookModal={openPingHookModal}
                            deliveries={deliveries}
                            deliveryDetails={deliveryDetails}
                            getDeliveryDetails={getDeliveryDetails}
                        />
                    )}
                    {isLoading && <LoadingOverlay />}
                </Wrapper>
            </Card>
            {hook && isShowingDeleteModal && (
                <DeleteHookModal
                    accountId={hookAccountId}
                    hook={hook}
                    deleteHook={deleteHook}
                    dismissDeleteHookModal={dismissDeleteHookModal}
                />
            )}
            {hook && isShowingPingModal && (
                <PingHookModal
                    accountId={hookAccountId}
                    hook={hook}
                    pingHook={pingHook}
                    dismissPingHookModal={dismissPingHookModal}
                />
            )}
        </Page>
    );
};

const Wrapper = styled.div`
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;
