import { useState } from 'react';
import useSWR from 'swr';
import { CORE_API_HOSTNAME } from '../../../../env';
import fulfill from '../../../../fulfill';
import type { Subscription } from '../../../../types/webhooks';

const fetchHook = async (payload: {
    accountId: string;
    hookId: string;
}): Promise<Subscription> => {
    return await fulfill.get({
        accountId: payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/hooks/subscriptions/${payload.hookId}`,
        handlers: {
            200: (hook: Subscription) => hook,
        },
    });
};

const fetchDeleteHook = async (payload: {
    accountId: string;
    hookId: string;
}): Promise<void> => {
    return await fulfill.delete({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/hooks/subscriptions/${payload.hookId}`,
        accountId: payload.accountId,
        handlers: {
            200: () => {},
        },
    });
};

const fetchPingHook = async (payload: {
    accountId: string;
    hookId: string;
}): Promise<void> => {
    return await fulfill.post({
        accountId: payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/hooks/subscriptions/${payload.hookId}/ping`,
        handlers: {
            204: () => {},
        },
    });
};

export const useHookDetails = (hookAccountId: string, hookId: string) => {
    const [isDeleted, setIsDeleted] = useState(false);
    const { data: hook, isLoading } = useSWR(() => hookAccountId && hookId ? { accountId: hookAccountId, hookId } : null, fetchHook);

    const pingHook = async () => {
        await fetchPingHook({ accountId: hookAccountId, hookId });
    };

    const deleteHook = async () => {
        await fetchDeleteHook({ accountId: hookAccountId, hookId });
        setIsDeleted(true);
    };

    return {
        hook,
        isLoading,
        isDeleted,
        pingHook,
        deleteHook,
    };
};
