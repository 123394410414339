import { State } from '../../../reducer';
import { createSelector } from 'reselect';
import { PayoutDestinationWithBankAccounts } from '../../../settings/payoutDestinations/PayoutDestinationsList';
import { CustomerDueDiligenceCase } from '../../../types/management-auth';
import type { PayoutDestinationBalance, PayoutDestinationConfig, PayoutDestinationTransfersEntry, PayoutState } from '../../types';
import sortBy  from 'lodash/sortBy';

export const isLoadingAccountConfig = (accountId: string) => (state: PayoutState) => state.merchant.config.account.loading[accountId] || false;
export const accountConfig = (accountId: string) => (state: PayoutState) => state.merchant.config.account.config[accountId] || null;

export const isLoadingBankAccountConfigs = (accountId: string) => (state: PayoutState) => state.merchant.config.bankAccount.loading[accountId] || false;
export const bankAccountConfigsRaw = (accountId: string) => (state: PayoutState) => state.merchant.config.bankAccount.bankAccounts[accountId] || {};
export const bankAccountConfigs = (accountId: string) => createSelector(
    bankAccountConfigsRaw(accountId),
    (accounts) => Object.values(accounts)
);

export const isLoadingPayoutDestinationConfigs = (accountId: string) => (state: PayoutState) => state.merchant.config.payoutDestination.loading[accountId] || false;
export const payoutDestinationConfigsRaw = (accountId: string) => (state: PayoutState) => state.merchant.config.payoutDestination.payoutDestinations[accountId] || {};
export const payoutDestinationConfigs = (accountId: string) => createSelector(
    payoutDestinationConfigsRaw(accountId),
    (payoutDestinations) => sortBy(Object.values(payoutDestinations), 'created_at').reverse()
);

export const isLoadingBankAccountPayoutDestinationConfigs = (accountId: string) => (state: PayoutState) => state.merchant.config.bankAccountPayoutDestination.loading[accountId] || false;
export const bankAccountPayoutDestinationConfigsRaw = (accountId: string) => (state: PayoutState) => state.merchant.config.bankAccountPayoutDestination.bankAccountPayoutDestinations[accountId] || {};
export const bankAccountPayoutDestinationConfigs = (accountId: string) => createSelector(
    bankAccountPayoutDestinationConfigsRaw(accountId),
    (basls) => Object.values(basls)
);

export const isLoadingConfigRfcs = (accountId: string) => (state: PayoutState) => state.merchant.config.rfc.loading[accountId] || false;
export const configRfcsRaw = (accountId: string) => (state: PayoutState) => state.merchant.config.rfc.rfcs[accountId] || {};
export const configRfcs = (accountId: string) => createSelector(
    configRfcsRaw(accountId),
    (rfcs) => Object.values(rfcs)
);

type ExternalPayoutState = { ['payout.config']: PayoutState['merchant']['config'] }

export const externalSelectors = {
    account: createSelector(
        (accountId: string) => accountId,
        (accountId) => ({
            isLoading: (state: ExternalPayoutState) => state['payout.config'].account.loading[accountId] || false,
            config: (state: ExternalPayoutState) => state['payout.config'].account.config[accountId] || null,
            hasPayout: (state: ExternalPayoutState) => state['payout.config'].account.config[accountId] !== null,
        })
    ),
    bankAccounts: createSelector(
        (accountId: string) => accountId,
        (accountId) => ({
            isLoading: (state: ExternalPayoutState) => state['payout.config'].bankAccount.loading[accountId] || false,
            bankAccountsRaw: (state: ExternalPayoutState) => state['payout.config'].bankAccount.bankAccounts[accountId] || {},
            bankAccounts: createSelector(
                (state: ExternalPayoutState) => state['payout.config'].bankAccount.bankAccounts[accountId] || {},
                (accounts) => Object.values(accounts)
            ),
        })
    ),
    payoutDestinations: createSelector(
        (accountId: string) => accountId,
        (accountId) => ({
            isLoading: (state: ExternalPayoutState) => state['payout.config'].payoutDestination.loading[accountId] || false,
            payoutDestinationConfigsRaw: (state: ExternalPayoutState) => state['payout.config'].payoutDestination.payoutDestinations[accountId] || {},
            payoutDestinationConfigs: createSelector(
                (state: ExternalPayoutState) => state['payout.config'].payoutDestination.payoutDestinations[accountId] || {},
                (payoutDestinations) => Object.values(payoutDestinations)
            ),
        })
    ),
    links: createSelector(
        (accountId: string) => accountId,
        (accountId) => ({
            isLoading: (state: ExternalPayoutState) => state['payout.config'].bankAccountPayoutDestination.loading[accountId] || false,
            linksRaw: (state: ExternalPayoutState) => state['payout.config'].bankAccountPayoutDestination.bankAccountPayoutDestinations[accountId] || {},
            links: (state: ExternalPayoutState) => Object.values(state['payout.config'].bankAccountPayoutDestination.bankAccountPayoutDestinations[accountId] || {}),
        })
    ),
    rfcs: createSelector(
        (accountId: string) => accountId,
        (accountId) => ({
            isLoading: (state: ExternalPayoutState) => state['payout.config'].rfc.loading[accountId] || false,
            rfcsRaw: (state: ExternalPayoutState) => state['payout.config'].rfc.rfcs[accountId] || {},
            rfcs: createSelector(
                (state: ExternalPayoutState) => state['payout.config'].rfc.rfcs[accountId] || {},
                (rfcs) => Object.values(rfcs)
            ),
        })
    ),
    payoutDestinationsBalances: createSelector(
        (accountId: string) => accountId,
        (accountId) => ({
            isLoading: (state: ExternalPayoutState) => state['payout.config'].payoutDestinationBalances.loading || false,
            payoutDestinationBalances: (state: ExternalPayoutState) => state['payout.config'].payoutDestinationBalances.balancesByIds[accountId] || {},
        })
    ),
    payoutDestinationsTransfers: createSelector(
        (accountId: string) => accountId,
        (accountId) => ({
            isLoading: (state: ExternalPayoutState) => state['payout.config'].payoutDestinationsTransfers.loading || false,
            payoutDestinationsTransfers: (state: ExternalPayoutState) => state['payout.config'].payoutDestinationsTransfers.transfersByIds[accountId] || {},
            nextPageTokens: (state: ExternalPayoutState) => state['payout.config'].payoutDestinationsTransfers.nextPageTokensById[accountId] || {},
        })
    ),
} as const;

export const createPdSelector = (accountId: string, pdId: string) => createSelector(
    externalSelectors.payoutDestinations(accountId).isLoading,
    externalSelectors.payoutDestinations(accountId).payoutDestinationConfigsRaw,
    (loading, payoutDestinations) => ({
        loading,
        // typescript behaves rather odd here for some reason..
        payoutDestination: payoutDestinations[pdId] as PayoutDestinationConfig | undefined,
    })
);

export const createPdBalancesSelector = (accountId: string, pdId: string) => createSelector(
    externalSelectors.payoutDestinationsBalances(accountId).isLoading,
    externalSelectors.payoutDestinationsBalances(accountId).payoutDestinationBalances,
    (loading, payoutDestinationsBalances) => ({
        loading,
        payoutDestinationBalances: payoutDestinationsBalances[pdId] as PayoutDestinationBalance[] | undefined,
    })
);


export const createPdTransfersSelector = (accountId: string, pdId: string) => createSelector(
    externalSelectors.payoutDestinationsTransfers(accountId).isLoading,
    externalSelectors.payoutDestinationsTransfers(accountId).payoutDestinationsTransfers,
    externalSelectors.payoutDestinationsTransfers(accountId).nextPageTokens,
    (loading, payoutDestinationsTransfers, nextPageTokens) => ({
        loading,
        payoutDestinationTransfers: payoutDestinationsTransfers[pdId] as PayoutDestinationTransfersEntry[] | undefined,
        nextPageToken: nextPageTokens[pdId] as string | undefined,
    })
);



const byPayoutDestinationsCreatedAtDesc = (a: PayoutDestinationWithBankAccounts, b: PayoutDestinationWithBankAccounts) => {
    if (a.created_at > b.created_at) {
        return -1;
    }
    if (a.created_at < b.created_at) {
        return 1;
    }
    return 0;
};

export const pdSelector = (accountId: string) => createSelector(
    externalSelectors.bankAccounts(accountId).bankAccountsRaw,
    externalSelectors.bankAccounts(accountId).isLoading,
    externalSelectors.links(accountId).links,
    externalSelectors.links(accountId).isLoading,
    externalSelectors.payoutDestinations(accountId).payoutDestinationConfigs,
    externalSelectors.payoutDestinations(accountId).isLoading,
    (bankAccounts, loadingBankAccounts, links, loadingLinks, payoutDestinations, loadingPayoutDestinations) => ({
        payoutDestinations: payoutDestinations.map<PayoutDestinationWithBankAccounts>((pd) => {
            const pdLinks = links.filter((l) => l.payout_destination_config_id === pd.id);
            const linkedBankAccounts = pdLinks
                .map((l) => bankAccounts[l.config_bank_account_id])
                .filter((ba) => ba !== undefined);
            return {
                ...pd,
                links: pdLinks,
                bankAccounts: linkedBankAccounts,
            };
        }).sort(byPayoutDestinationsCreatedAtDesc),
        loading: loadingBankAccounts || loadingLinks || loadingPayoutDestinations,
    })
);

const getMostRecentCase = (cases: Record<string, CustomerDueDiligenceCase>) => {
    // get most recent case if it exist, query should only return one
    return Object.values(cases).find((x) => x);
};

const getMostRecentCaseEvent = (cddCase: CustomerDueDiligenceCase | undefined) => {
    const events = cddCase?.events || [];
    if (events.length > 0) {
        return events[events.length - 1];
    }
};

export const payoutInfoSelector = (accountId: string) => createSelector(
    (state: State) => state['management.cdd'].cases[accountId] || {},
    (state: State) => state['management.cdd'].loading[accountId] || false,
    externalSelectors.account(accountId).hasPayout,
    externalSelectors.account(accountId).config,
    (cases, loading, hasPayout, config) => {
        const cddCase = getMostRecentCase(cases);
        const lastCddEvent = getMostRecentCaseEvent(cddCase);
        return {
            cddCase,
            lastCddEvent,
            loading,
            hasPayout,
            config,
        };
    }
);
