export const validatePositiveNumber = (message: string) => {
    return (value: number) => {
        if (!value) {
            return;
        }
        let valueToValidate = 0;
        if (typeof value === 'number') {
            valueToValidate = value;
        } else if (typeof value === 'string') {
            valueToValidate = Number((value as string).replace(/,|\s/g, ''));
        }
        const isValid = valueToValidate > 0;
        if (!isValid) {
            return message;
        }
    };
};

export const validateInRange = (message: string, gte: number, lte: number) => {
    return (value: number) => {
        if (!value) {
            return;
        }
        let valueToValidate;
        if (typeof value === 'number') {
            valueToValidate = value;
        } else if (typeof value === 'string') {
            valueToValidate = Number((value as string).replace(/,|\s/g, ''));
        }
        const isValid = typeof valueToValidate === 'number' && valueToValidate >= gte && valueToValidate <= lte;
        if (!isValid) {
            return message;
        }
    };
};


export const validateGreaterThan = (gt: number | string, message: string) => {
    return (value: number) => {
        if (!value) {
            return;
        }
        let gtNumber;
        if (typeof gt === 'number') {
            gtNumber = gt;
        } else if (typeof value === 'string') {
            gtNumber = Number((gt as string).replace(/,|\s/g, ''));
        }
        let valueToValidate;
        if (typeof value === 'number') {
            valueToValidate = value;
        } else if (typeof value === 'string') {
            valueToValidate = Number((value as string).replace(/,|\s/g, ''));
        }
        const isValid = typeof valueToValidate === 'number' && typeof gtNumber === 'number' && valueToValidate > gtNumber;
        if (!isValid) {
            return message;
        }
    };
};
