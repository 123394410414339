import { useTranslation } from 'react-i18next';
import { H1, H6, P } from '../../../components/Typography';
import View from '../../../components/View';
import { formatMoney } from '../../../helpers/formatters';
import { getCurrentLanguage } from '../../../i18n';
import { DAILY_PAYOUT_PER_MONTH_LOCATION_PRICE } from '../constants';
import { lngToCurrency } from '../helpers';
import useDailyPayout from '../hooks/useDailyPayout';
import DailyPayoutsEnabledStatus from './DailyPayoutsEnabledStatus';
import DailyPayoutsToggleButton from './DailyPayoutsToggleButton';
import { Box, BoxInner } from './elements';

export default function DailyPayoutsBox() {
    const { t, i18n } = useTranslation();
    const lng = getCurrentLanguage(i18n);
    const currency = lngToCurrency(lng);
    const { enableDailyPayouts, disableDailyPayouts, canEnable, active, status, requested, isLoading } = useDailyPayout();

    return (
        <Box>
            <BoxInner>
                <View gap={8} mb={15}>
                    <H6>
                        {t('settings.additional_services.daily_payouts.title')}
                    </H6>
                    <DailyPayoutsEnabledStatus active={active} status={status} requested={requested} isLoading={isLoading} />
                </View>
                <View direction="column" alignItems="flex-start">
                    <P>{t('settings.additional_services.daily_payouts.per_month_per_location')}</P>
                    <H1>{formatMoney(lng, DAILY_PAYOUT_PER_MONTH_LOCATION_PRICE, currency)}</H1>
                </View>
            </BoxInner>
            <BoxInner>
                <P style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    margin: 0,
                }}>
                    {t('settings.additional_services.daily_payouts.description')}
                </P>
            </BoxInner>
            <BoxInner>
                <DailyPayoutsToggleButton
                    onEnable={enableDailyPayouts}
                    onDisable={disableDailyPayouts}
                    requested={requested}
                    disabled={!canEnable} status={status}
                    active={active}
                />
            </BoxInner>
        </Box>
    );
}
