import styled from 'styled-components/macro';
import { palette, slideIn } from '../../../styles/constants';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    gap: 22px;

    animation: ${slideIn} 0.3s ease-in-out;
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 22px;
    gap: 22px;
    align-items: flex-start;
    min-width: 360px;
    max-width: 360px;

    border-radius: 15px;
    border: 1px solid ${palette.neutral[200]};
    background: white;
    overflow: hidden;

    height: fit-content;

    .inner {
        width: 100%;
        padding-inline: 22px;
        &:first-child {
            padding-block: 22px;
            background: ${palette.primary[50]};
            gap: 8px;
            h6 {
                color: ${palette.primary[700]};
                font-size: 15px;
                font-weight: 700;
                line-height: 22px;
            }
            h1 {
                margin: 0;
                font-size: 26.264px;
                font-style: normal;
                font-weight: 700;
                line-height: 33.768px;
                letter-spacing: -0.525px;
            }
        }
    }
`;

export const BoxInner = styled.div.attrs(props => ({ ...props, className: ['inner', props.className].filter(x => x).join(' ') }))`
    display: flex;
    flex-direction: column;
`;

export const PendingBadge = styled.div`
    display: flex;
    padding: 1.876px 9.38px;
    justify-content: center;
    align-items: center;
    gap: 5.628px;
    border-radius: 22.512px;
    background: ${palette.primary[100]};
    outline: 1px solid ${palette.primary[300]};

    & > span {
        color: ${palette.primary[500]};
        text-align: center;
        font-size: 11.256px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.76px; /* 166.667% */
    }
`;

export const EnabledBadge = styled.div`
    display: flex;
    padding: 1.876px 9.38px;
    justify-content: center;
    align-items: center;
    gap: 5.628px;
    border-radius: 22.512px;
    background: ${palette.success[500]};

    & > span {
        color: white;
        text-align: center;
        font-size: 11.256px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.76px; /* 166.667% */
    }
`;
