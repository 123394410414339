import { Suspense } from 'react';

import Routes from './Routes';

import effects from './effects';
import * as reducer from './reducer';

const Settings = () => (
    <>
        <Suspense fallback={null}>
            <Routes />
        </Suspense>
    </>
);

export { Settings, effects, reducer };
