import { useTranslation } from 'react-i18next';
import { EnabledBadge, PendingBadge } from './elements';
import type { ServiceFeatureStatus } from '../../../types/management-auth/generated';

type SplitPaymentEnabledStatusProps = {
    isLoading: boolean;
    status?: ServiceFeatureStatus;
};

export default function SplitPaymentEnabledStatus({
    isLoading,
    status,
}: SplitPaymentEnabledStatusProps) {
    const { t } = useTranslation();

    if (isLoading || !status) {
        return null;
    }

    if (status === 'pending') {
        return (
            <PendingBadge>
                <span>
                    {t('settings.additional_services.split_payment.pending')}
                </span>
            </PendingBadge>
        );
    }
    return (
        <EnabledBadge>
            <span>
                {t('settings.additional_services.split_payment.on')}
            </span>
        </EnabledBadge>
    );
};
