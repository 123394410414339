import _keyBy from 'lodash/keyBy';
import _sortBy from 'lodash/sortBy';
import qs from 'qs';
import { Dispatch } from 'redux';
import apiRequest, { isApiError } from '../apiRequest';
import { createAccountIdChangedCaseReducer } from '../auth/useAccountId';
import { CORE_API_HOSTNAME } from '../env';
import { Causes, errorExternalStore } from '../errors';
import { ApprovalsPayoutDestination, ApprovalsPayoutDestinationResponse } from '../types/management-auth/generated';
import { createSwitchlessReducer } from '../util/switchlessReducer';

export type ApprovalsPayoutDestinationQuery = Partial<{
    case_status: ApprovalsPayoutDestinationResponse['case_status'][];
}>;


export type ApprovalsState = {
    loading: Record<string, boolean>;
    approvals: Record<string, ApprovalsPayoutDestinationResponse[]>;
    query: Record<string, ApprovalsPayoutDestinationQuery>;
    approvalById: Record<string, ApprovalsPayoutDestinationResponse>;
};

const initialState: ApprovalsState = {
    loading: {},
    approvals: {},
    query: {},
    approvalById: {},
};

const namespace = 'management.approvals' as const;

const { setState, reducer } = createSwitchlessReducer({
    initialState,
    namespace,
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

const setError = (err: any) => {
    if (isApiError(err)) {
        return errorExternalStore.dispatch('setError', {
            cause: Causes.UnhandledStatus, status: err.status, statusText: err.statusText, message: err.message, 'request-id': err.requestId,
        });
    }
    return errorExternalStore.dispatch('setError', {
        cause: Causes.UnhandledStatus, message: err.message,
    });
};

const createSellerApprovalsActions = (dispatch: Dispatch, accountId: string) => {
    const baseUrl = (requestAccountId: string) => `${CORE_API_HOSTNAME}/v1/accounts/${requestAccountId}/management/settings/approvals/payout-destinations`;

    const deleteSellerApproval = async (requestAccountId: string, sellerId: string, deleteActive?: boolean) => {
        const deleteActiveQueryParams = new URLSearchParams();
        if (deleteActive) {
            deleteActiveQueryParams.append('delete_active', 'true');
        }
        await apiRequest<{
            payout_destinations: ApprovalsPayoutDestinationResponse[];
        }>(
            'DELETE',
            requestAccountId,
            `${baseUrl(requestAccountId)}/${sellerId}?${deleteActiveQueryParams.toString()}`
        );
        dispatch(setState('deletedApproval', state => ({
            ...state,
            approvals: {
                ...state.approvals,
                [requestAccountId]: [
                    ...(state.approvals[requestAccountId]),
                ].map(approval => {
                    if (approval.id !== sellerId) {
                        return approval;
                    }
                    return {
                        ...approval,
                        case_status: 'ARCHIVED',
                    };
                }),
            },
            approvalById: {
                [sellerId]: {
                    ...(state.approvalById[sellerId]),
                    case_status: 'ARCHIVED',
                },
            },
        })));
    };

    const getSellerApprovals = async (requestAccountId: string, query?: ApprovalsPayoutDestinationQuery) => {
        dispatch(setState('getApprovalsAttempt', state => ({
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const approvals = await apiRequest<{
                payout_destinations: ApprovalsPayoutDestinationResponse[];
            }>(
                'GET',
                requestAccountId,
                `${baseUrl(requestAccountId)}${query ? `?${qs.stringify(query)}` : ''}`
            );
            dispatch(
                setState('getApprovalsSuccess', (state) => ({
                    approvals: {
                        ...state.approvals,
                        [requestAccountId]: _sortBy(Object.values({
                            ..._keyBy(approvals.payout_destinations, 'id'),
                        }), 'created_at').reverse(),
                    },
                    query: {
                        ...state.query,
                        [requestAccountId]: query || {},
                    },

                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                }))
            );
        } catch (err) {
            console.error(err);
            if (err.status === 404) {
                // if merchant has not live mode, return an empty list
                dispatch(
                    setState('getApprovalsSuccess', (state) => ({
                        approvals: {
                            ...state.approvals,
                            [requestAccountId]: [],
                        },
                        query: {
                            ...state.query,
                            [requestAccountId]: query || {},
                        },
                        loading: {
                            ...state.loading,
                            [requestAccountId]: false,
                        },
                    }))
                );
            } else {
                dispatch(setState('getApprovalsFailure', state => ({
                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                })));
                errorExternalStore.dispatch('setError', err);
            }
        }
    };

    const getSellerApproval = async (requestAccountId: string, id: string) => {
        dispatch(setState('getApprovalsAttempt', state => ({
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
            approvalById: {},
        })));

        try {
            const approval = await apiRequest<
                ApprovalsPayoutDestinationResponse
            >(
                'GET',
                requestAccountId,
                `${baseUrl(requestAccountId)}/${id}`
            );
            dispatch(
                setState('getApprovalsSuccess', (state) => ({
                    approvalById: {
                        [id]: approval,
                    },
                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                }))
            );
        } catch (err) {
            console.error(err);
            if (err.status !== 200) {
                dispatch(setState('getApprovalsFailure', state => ({
                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                })));
                errorExternalStore.dispatch('setError', err);
            }
        }
    };


    const postSellerApproval = async (requestAccountId: string, data: ApprovalsPayoutDestination, queryString?: string): Promise<ApprovalsPayoutDestinationResponse | null> => {
        dispatch(setState('postApprovalAttempt', state => ({
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const approvalResponse = await apiRequest<ApprovalsPayoutDestinationResponse>('POST', requestAccountId, [baseUrl(requestAccountId), queryString].filter(x => x).join('?'), undefined, data);
            dispatch(
                setState('postApprovalSuccess', (state) => ({
                    cases: {
                        ...state.approvals,
                        [requestAccountId]: {
                            approvalResponse,
                            ...state.approvals[requestAccountId],
                        },
                    },
                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                }))
            );
            return approvalResponse;
        } catch (err) {
            dispatch(setState('postApprovalFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            errorExternalStore.dispatch('setError', err);
            return null;
        }
    };

    return {
        deleteSellerApproval,
        getSellerApprovals,
        postSellerApproval,
        getSellerApproval,
    };
};

export { createSellerApprovalsActions, initialState, namespace, reducer };
