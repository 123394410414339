import React from 'react';
import styled from 'styled-components/macro';

import arrow_left from './arrow_left';
import arrow_right_small from './arrow_right_small';
import arrow_left_stop from './arrow_left_stop';
import caret_down from './caret_down';
import check_circle from './check_circle';
import check from './check';
import close from './close';
import close_small from './close_small';
import deleteIcon from './delete';
import document from './document';
import document_small from './document_small';
import download from './download';
import eye from './eye';
import eyeStrikethrough from './eye-strikethrough';
import info_circle from './info_circle';
import payments from './payments';
import plus from './plus';
import refresh from './refresh';
import revert from './revert';
import save from './save';
import search from './search';
import snail from './snail';
import tabular from './tabular';
import excel from './excel';
import pdf from './pdf';
import xml from './xml';
import edit_pen from './edit_pen';
import ellipsis_vertical from './ellipsis_vertical';
import exclamation from './exclamation';

export const iconMap = {
    arrow_left,
    arrow_left_stop,
    arrow_right_small,
    caret_down,
    check_circle,
    check,
    close,
    close_small,
    delete: deleteIcon,
    document,
    document_small,
    download,
    eye,
    eyeStrikethrough,
    info_circle,
    payments,
    pdf,
    plus,
    refresh,
    revert,
    save,
    search,
    snail,
    tabular,
    xml,
    excel,
    edit_pen,
    ellipsis_vertical,
    exclamation,
};

interface IconProps extends React.DOMAttributes<HTMLSpanElement> {
    icon: keyof typeof iconMap;
    fill?: string;
    pointer?: boolean;
    [key: string]: any;
}

const Icon = (
    {
        fill,
        icon,
        pointer,
        ...otherProps
    }: IconProps
) => {
    if (iconMap[icon]) {
        return (
            <IconWrapper
                fill={fill}
                pointer={pointer || false}
                {...otherProps}
                children={iconMap[icon]()}
            ></IconWrapper>
        );
    }
    return null;
};

interface IconWrapperProps {
    fill?: string;
    pointer: boolean;
}
const IconWrapper = styled.span<IconWrapperProps>`
    display: inline-block;

    &.flip {
        transform: rotate(180deg);
    }
    &.clickable {
        cursor: pointer;
    }

    color: ${(props) => props.fill || 'currentColor'};
    ${(props) => (props.pointer ? 'cursor: pointer;' : '')}
`;

export default Icon;
