
export const smsOrPush = (options: string[] | undefined, values: string[] | undefined) => {
    const pushList = ['vipps', 'payex.vipps', 'payex.swish', 'bambora.vipps'];
    if (options) {
        if (options.length === 1 && pushList.includes(options[0])) {
            return 'push';
        }
    }
    if (values) {
        if (values.length === 1 && pushList.includes(values[0])) {
            return 'push';
        }
    }
    return 'sms';
};

export const toAllowDifferentBillingShippingAddress = (value: string[]): 'none' | 'b2b' | 'b2c' | 'b2b_b2c' => {
    const hasB2B = value.includes('b2b');
    const hasB2C = value.includes('b2c');
    if (hasB2B && hasB2C) {
        return 'b2b_b2c';
    } else if (hasB2B) {
        return 'b2b';
    } else if (hasB2C) {
        return 'b2c';
    }
    return 'none';
};
