import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import { combineReducers, Dispatch } from 'redux';
import apiRequest, { IApiError } from '../../../apiRequest';
import { createAccountIdChangedCaseReducer } from '../../../auth/useAccountId';
import { createSwitchlessReducer } from '../../../util/switchlessReducer';
import { actions as bankAccountActions } from '../../general/bankaccount/actions';
import { addApiErrorMessageAction, addSuccessMessageAction } from '../../general/message/actions';
import type { ApiModel, ConfigAccount, ConfigBankAccount, ConfigBankAccountPayoutDestination, ConfigRfc, PayoutDestinationBalance, PayoutDestinationConfig, PayoutDestinationConfigDraft, PayoutDestinationTransfersEntry } from '../../types';

type ApiConfigRfc = ApiModel<ConfigRfc>
type ApiConfigAccount = ApiModel<ConfigAccount>
type ApiConfigBankAccount = ApiModel<ConfigBankAccount>
type ApiPayoutDestinationConfig = ApiModel<PayoutDestinationConfig>
type ApiConfigBankAccountPayoutDestination = ApiModel<ConfigBankAccountPayoutDestination>

const transformApiConfigRfc = (entity: ApiConfigRfc): ConfigRfc => ({
    ...entity,
    updated_at: new Date(entity.updated_at),
    created_at: new Date(entity.created_at),
});
const transformApiConfigAccount = (entity: ApiConfigAccount): ConfigAccount => ({
    ...entity,
    updated_at: new Date(entity.updated_at),
    created_at: new Date(entity.created_at),
});
const transformApiConfigBankAccount = (entity: ApiConfigBankAccount): ConfigBankAccount => ({
    ...entity,
    updated_at: new Date(entity.updated_at),
    created_at: new Date(entity.created_at),
});

const transformApiConfigPayoutDestination = (entity: ApiPayoutDestinationConfig): PayoutDestinationConfig => ({
    ...entity,
    updated_at: new Date(entity.updated_at),
    created_at: new Date(entity.created_at),
});
const transformApiConfigBankAccountPayoutDestination = (entity: ApiConfigBankAccountPayoutDestination): ConfigBankAccountPayoutDestination => ({
    ...entity,
    updated_at: new Date(entity.updated_at),
    created_at: new Date(entity.created_at),
});

type CommonState = {
    loading: Record<string, boolean>;
    lastError: IApiError | Error | null;
}

const initialCommonState: CommonState = {
    loading: {},
    lastError: null,
};

type ConfigAccountState = Readonly<CommonState & {
    config: Record<string, ConfigAccount | null>;
}>

const initalConfigAccountState: ConfigAccountState = {
    ...initialCommonState,
    config: {},
};

type ConfigRfcState = Readonly<CommonState & {
    rfcs: Record<string, Record<string, ConfigRfc>>;
}>

const initialConfigRfcState: ConfigRfcState = {
    ...initialCommonState,
    rfcs: {},
};

type ConfigBankAccountState = Readonly<CommonState & {
    bankAccounts: Record<string, Record<string, ConfigBankAccount>>;
}>

const initialConfigBankAccountState: ConfigBankAccountState = {
    ...initialCommonState,
    bankAccounts: {},
};

type PayoutDestinationConfigState = Readonly<CommonState & {
    payoutDestinations: Record<string, Record<string, PayoutDestinationConfig>>;
}>

const initialConfigPayoutDestinationState: PayoutDestinationConfigState = {
    ...initialCommonState,
    payoutDestinations: {},
};

type ConfigBankAccountPayoutDestinationState = Readonly<CommonState & {
    bankAccountPayoutDestinations: Record<string, Record<string, ConfigBankAccountPayoutDestination>>;
}>

const initialConfigBankAccountPayoutDestinationState: ConfigBankAccountPayoutDestinationState = {
    ...initialCommonState,
    bankAccountPayoutDestinations: {},
};


type PayoutDestinationBalanceState = Readonly<CommonState & {
    balancesByIds: Record<string, Record<string, PayoutDestinationBalance[]>>;
}>


const initialPayoutDestinationBalanceState: PayoutDestinationBalanceState = {
    ...initialCommonState,
    balancesByIds: {},
};

type PayoutDestinationTransfersState = Readonly<CommonState & {
    transfersByIds: Record<string, Record<string, PayoutDestinationTransfersEntry[]>>;
    nextPageTokensById: Record<string, Record<string, string>>;
}>

const initialPayoutDestinationTransfersState: PayoutDestinationTransfersState = {
    ...initialCommonState,
    transfersByIds: {},
    nextPageTokensById: {},
};

export const namespace = 'payout.config' as const;

export type ConfigState = Readonly<{
    bankAccount: ConfigBankAccountState;
    account: ConfigAccountState;
    rfc: ConfigRfcState;
    payoutDestination: PayoutDestinationConfigState;
    bankAccountPayoutDestination: ConfigBankAccountPayoutDestinationState;
    payoutDestinationBalances: PayoutDestinationBalanceState;
    payoutDestinationsTransfers: PayoutDestinationTransfersState;
}>

const {
    reducer: account,
    setState: accountSetState,
} = createSwitchlessReducer({
    namespace: `${namespace}.account`,
    initialState: initalConfigAccountState,
    globalReducer: createAccountIdChangedCaseReducer(() => initalConfigAccountState),
});

const {
    reducer: payoutDestination,
    setState: payoutDestinationSetState,
} = createSwitchlessReducer({
    namespace: `${namespace}.payoutDestination`,
    initialState: initialConfigPayoutDestinationState,
    globalReducer: createAccountIdChangedCaseReducer(() => initialConfigPayoutDestinationState),
});

const {
    reducer: bankAccount,
    setState: bankAccountSetState,
} = createSwitchlessReducer({
    namespace: `${namespace}.bankAccount`,
    initialState: initialConfigBankAccountState,
    globalReducer: createAccountIdChangedCaseReducer(() => initialConfigBankAccountState),
});

const {
    reducer: rfc,
    setState: rfcSetState,
} = createSwitchlessReducer({
    namespace: `${namespace}.rfc`,
    initialState: initialConfigRfcState,
    globalReducer: createAccountIdChangedCaseReducer(() => initialConfigRfcState),
});

const {
    reducer: bankAccountPayoutDestination,
    setState: bankAccountPayoutDestinationSetState,
} = createSwitchlessReducer({
    namespace: `${namespace}.bankAccountPayoutDestination`,
    initialState: initialConfigBankAccountPayoutDestinationState,
    globalReducer: createAccountIdChangedCaseReducer(() => initialConfigBankAccountPayoutDestinationState),
});

const {
    reducer: payoutDestinationBalances,
    setState: payoutDestinationBalancesSetState,
} = createSwitchlessReducer({
    namespace: `${namespace}.payoutDestinationBalances`,
    initialState: initialPayoutDestinationBalanceState,
    globalReducer: createAccountIdChangedCaseReducer(() => initialPayoutDestinationBalanceState),
});

const {
    reducer: payoutDestinationsTransfers,
    setState: payoutDestinationsTransfersSetState,
} = createSwitchlessReducer({
    namespace: `${namespace}.payoutDestinationTransfers`,
    initialState: initialPayoutDestinationTransfersState,
    globalReducer: createAccountIdChangedCaseReducer(() => initialPayoutDestinationTransfersState),
});

export const reducer = combineReducers<ConfigState>({
    account,
    payoutDestination,
    bankAccount,
    rfc,
    bankAccountPayoutDestination,
    payoutDestinationBalances,
    payoutDestinationsTransfers,
});

export const createConfigActions = (
    dispatch: Dispatch,
    accountId: string,
    apiUrl: string
) => {
    const baseV1Url = (requestAccountId: string) => `${apiUrl}/v1/accounts/${requestAccountId}/payout`;
    const baseV2Url = (requestAccountId: string) => `${apiUrl}/v2/accounts/${requestAccountId}/payout`;

    const getAccountConfig = async (requestAccountId: string) => {
        dispatch(accountSetState('getAccountConfigAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { config } = await apiRequest<{ config: ApiConfigAccount }>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/config`);
            dispatch(accountSetState('getAccountConfigSuccess', state => ({
                ...state,
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                config: {
                    ...state.config,
                    [requestAccountId]: transformApiConfigAccount(config),
                },
            }
            )));
        } catch (err) {
            // 404 error is expected if first-time user of payout. For now just set config to null.
            if (err.status === 404) {
                dispatch(accountSetState('getAccountConfigSuccess', state => ({
                    ...state,
                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                    config: {
                        ...state.config,
                        [requestAccountId]: null,
                    },
                }
                )));
                return;
            }
            dispatch(accountSetState('getAccountConfigFailure', state => ({
                ...state,
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            }
            )));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const updateAccountConfig = async (requestAccountId: string, patch: Partial<ConfigAccount>) => {
        dispatch(accountSetState('updateAccountConfigAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { config } = await apiRequest<{ config: ApiConfigAccount }>('PATCH', requestAccountId, `${baseV1Url(requestAccountId)}/config`, undefined, patch);
            dispatch(accountSetState('updateAccountConfigSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                config: {
                    ...state.config,
                    [requestAccountId]: transformApiConfigAccount(config),
                },
            })));
            dispatch(addSuccessMessageAction('notifications.update_account_config_success'));
            return true;
        } catch (err) {
            dispatch(accountSetState('updateAccountConfigFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
            return false;
        }
    };

    const deleteAccountConfig = async (requestAccountId: string) => {
        dispatch(accountSetState('deleteAccountConfigAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            await apiRequest<ApiConfigAccount>('DELETE', requestAccountId, `${baseV1Url(requestAccountId)}/config`);
            dispatch(accountSetState('deleteAccountConfigSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                config: {
                    ...state.config,
                    [requestAccountId]: null,
                },
            })));
            dispatch(addSuccessMessageAction('notifications.delete_account_config_success'));
        } catch (err) {
            dispatch(accountSetState('deleteAccountConfigFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const getConfigRfcs = async (requestAccountId: string) => {
        dispatch(rfcSetState('getConfigRfcsAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { rfcs } = await apiRequest<{ rfcs: ApiConfigRfc[] }>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/config-rfcs`);
            dispatch(rfcSetState('getConfigRfcsSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                rfcs: {
                    ...state.rfcs,
                    [requestAccountId]: {
                        ...state.rfcs[requestAccountId],
                        ..._keyBy(rfcs.map(transformApiConfigRfc), 'id'),
                    },
                },
            })));
        } catch (err) {
            dispatch(rfcSetState('getConfigRfcsFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const getConfigRfc = async (requestAccountId: string, rfcId: string) => {
        dispatch(rfcSetState('getConfigRfcAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { rfc } = await apiRequest<{ rfc: ApiConfigRfc }>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/config-rfcs/${rfcId}`);
            dispatch(rfcSetState('getConfigRfcSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                rfcs: {
                    ...state.rfcs,
                    [requestAccountId]: {
                        ...state.rfcs[requestAccountId],
                        [rfc.id]: transformApiConfigRfc(rfc),
                    },
                },
            })));
        } catch (err) {
            dispatch(rfcSetState('getConfigRfcFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const createConfigRfc = async (requestAccountId: string, post: ConfigRfc['data']) => {
        dispatch(rfcSetState('createConfigRfcAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { rfc } = await apiRequest<{ rfc: ApiConfigRfc }>(
                'POST',
                requestAccountId,
                `${baseV1Url(requestAccountId)}/config-rfcs`,
                undefined,
                post
            );
            dispatch(rfcSetState('createConfigRfcSuccess', state => ({
                rfcs: {
                    ...state.rfcs,
                    [requestAccountId]: {
                        ...state.rfcs[requestAccountId],
                        [rfc.id]: transformApiConfigRfc(rfc),
                    },
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addSuccessMessageAction('notifications.create_config_rfc_success'));
            return true;
        } catch (err) {
            dispatch(rfcSetState('createConfigRfcFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
            return false;
        }
    };

    const updateConfigRfc = async (requestAccountId: string, rfcId: string, patch: ConfigRfc) => {
        dispatch(rfcSetState('updateConfigRfcAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { rfc } = await apiRequest<{ rfc: ApiConfigRfc }>('PUT', requestAccountId, `${baseV1Url(requestAccountId)}/config-rfcs/${rfcId}`, undefined, patch);
            dispatch(rfcSetState('updateConfigRfcSuccess', state => ({
                rfcs: {
                    ...state.rfcs,
                    [requestAccountId]: {
                        ...state.rfcs[requestAccountId],
                        [rfc.id]: transformApiConfigRfc(rfc),
                    },
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addSuccessMessageAction('notifications.update_config_rfc_success'));
        } catch (err) {
            dispatch(rfcSetState('updateConfigRfcFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const deleteConfigRfc = async (requestAccountId: string, rfcId: string) => {
        dispatch(rfcSetState('deleteConfigRfcAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            await apiRequest<ApiConfigRfc>('DELETE', requestAccountId, `${baseV1Url(requestAccountId)}/config-rfcs/${rfcId}`);
            dispatch(rfcSetState('deleteConfigRfcSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                rfcs: {
                    ...state.rfcs,
                    [requestAccountId]: _omit(state.rfcs[requestAccountId], rfcId),
                },
            })));
            dispatch(addSuccessMessageAction('notifications.delete_config_rfc_success'));
        } catch (err) {
            dispatch(rfcSetState('deleteConfigRfcFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const getBankAccounts = async (requestAccountId: string) => {
        dispatch(bankAccountSetState('getBankAccountsAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { bank_accounts } = await apiRequest<{ bank_accounts: ApiConfigBankAccount[]}>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/bank-accounts`);
            dispatch(bankAccountSetState('getBankAccountsSuccess', state => ({
                bankAccounts: {
                    ...state.bankAccounts,
                    [requestAccountId]: {
                        ...state.bankAccounts[requestAccountId],
                        ..._keyBy(bank_accounts.map(transformApiConfigBankAccount), 'id'),
                    },
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(bankAccountActions.addBankAccounts({ bankAccounts: bank_accounts }));
        } catch (err) {
            dispatch(bankAccountSetState('getBankAccountsFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const getBankAccount = async (requestAccountId: string, bankAccountId: string) => {
        dispatch(bankAccountSetState('getBankAccountAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { bank_account } = await apiRequest<{ bank_account: ApiConfigBankAccount }>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/bank-accounts/${bankAccountId}`);
            dispatch(bankAccountSetState('getBankAccountSuccess', state => ({
                bankAccounts: {
                    ...state.bankAccounts,
                    [requestAccountId]: {
                        ...state.bankAccounts[requestAccountId],
                        [bank_account.id]: transformApiConfigBankAccount(bank_account),
                    },
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
        } catch (err) {
            dispatch(bankAccountSetState('getBankAccountFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const updateBankAccount = async (requestAccountId: string, bankAccountId: string, patch: Partial<Pick<ConfigBankAccount, 'is_default_for_currency' | 'nickname'>>) => {
        dispatch(bankAccountSetState('updateBankAccountAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { bank_account } = await apiRequest<{ bank_account: ApiConfigBankAccount }>('PATCH', requestAccountId, `${baseV1Url(requestAccountId)}/bank-accounts/${bankAccountId}`, undefined, patch);
            dispatch(bankAccountSetState('updateBankAccountSuccess', state => ({
                bankAccounts: {
                    ...state.bankAccounts,
                    [requestAccountId]: {
                        ...state.bankAccounts[requestAccountId],
                        [bank_account.id]: transformApiConfigBankAccount(bank_account),
                    },
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addSuccessMessageAction('notifications.update_bank_account_success'));
            return true;
        } catch (err) {
            dispatch(bankAccountSetState('updateBankAccountFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
            return false;
        }
    };

    const deleteBankAccount = async (requestAccountId: string, bankAccountId: string) => {
        dispatch(bankAccountSetState('deleteBankAccountAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            await apiRequest('DELETE', requestAccountId, `${baseV1Url(requestAccountId)}/bank-accounts/${bankAccountId}`);
            dispatch(bankAccountSetState('deleteBankAccountSuccess', state => ({
                bankAccounts: {
                    ...state.bankAccounts,
                    [requestAccountId]: _omit(state.bankAccounts[requestAccountId], bankAccountId),
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addSuccessMessageAction('notifications.delete_bank_account_success'));
        } catch (err) {
            dispatch(bankAccountSetState('deleteBankAccountFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const getPayoutDestinations = async (requestAccountId: string) => {
        dispatch(payoutDestinationSetState('getPayoutDestinationsAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { payout_destination_configs } = await apiRequest<{ payout_destination_configs: ApiPayoutDestinationConfig[]}>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/payout-destinations`);
            dispatch(payoutDestinationSetState('getPayoutDestinationsSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                payoutDestinations: {
                    ...state.payoutDestinations,
                    [requestAccountId]: {
                        ...state.payoutDestinations[requestAccountId],
                        ..._keyBy(payout_destination_configs.map(transformApiConfigPayoutDestination), 'id'),
                    },
                },
            })));
        } catch (err) {
            dispatch(payoutDestinationSetState('getPayoutDestinationsFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const getPayoutDestination = async (requestAccountId: string, id: string) => {
        dispatch(payoutDestinationSetState('getPayoutDestinationAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { payout_destination_config } = await apiRequest<{ payout_destination_config: ApiPayoutDestinationConfig }>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/payout-destinations/${id}`);
            dispatch(payoutDestinationSetState('getPayoutDestinationSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                payoutDestinations: {
                    ...state.payoutDestinations,
                    [requestAccountId]: {
                        ...state.payoutDestinations[requestAccountId],
                        [payout_destination_config.id]: transformApiConfigPayoutDestination(payout_destination_config),
                    },
                },
            })));
        } catch (err) {
            dispatch(payoutDestinationSetState('getPayoutDestinationFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };


    const getPayoutDestinationBalance = async (requestAccountId: string, payoutDestinationConfigurationId: string) => {
        dispatch(payoutDestinationBalancesSetState('getPayoutDestinationBalanceAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { payout_destination_balances } = await apiRequest<{ payout_destination_balances: PayoutDestinationBalance[] }>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/payout-destinations/${payoutDestinationConfigurationId}/balances`);
            dispatch(payoutDestinationBalancesSetState('getPayoutDestinationBalancesSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                balancesByIds: {
                    ...state.balancesByIds,
                    [requestAccountId]: {
                        ...state.balancesByIds[requestAccountId],
                        [payoutDestinationConfigurationId]: payout_destination_balances,
                    },
                },
            })));
        } catch (err) {
            dispatch(payoutDestinationBalancesSetState('getPayoutDestinationBalancesFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };


    const getPayoutDestinationTransfers = async (requestAccountId: string, payoutDestinationConfigurationId: string, nextPageToken: string | undefined) => {
        dispatch(payoutDestinationsTransfersSetState('getPayoutDestinationTransfersAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const params = nextPageToken ? `?page=${nextPageToken}` : '';
            const { entries, next_page_token } = await apiRequest<{ entries: PayoutDestinationTransfersEntry[]; next_page_token: string }>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/payout-destinations/${payoutDestinationConfigurationId}/transfers${params}`);
            dispatch(payoutDestinationsTransfersSetState('getPayoutDestinationTransfersSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                transfersByIds: {
                    ...state.transfersByIds,
                    [requestAccountId]: {
                        ...state.transfersByIds[requestAccountId],
                        [payoutDestinationConfigurationId]: nextPageToken ? ([
                            ...state.transfersByIds[requestAccountId][payoutDestinationConfigurationId],
                            ...entries,
                        ]) : entries,
                    },
                },
                nextPageTokensById: {
                    ...state.nextPageTokensById,
                    [requestAccountId]: {
                        ...state.nextPageTokensById[requestAccountId],
                        [payoutDestinationConfigurationId]: next_page_token,
                    },
                },
            })));
        } catch (err) {
            dispatch(payoutDestinationsTransfersSetState('getPayoutDestinationTransfersFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const createPayoutDestination = async (requestAccountId: string, draft: PayoutDestinationConfigDraft) => {
        dispatch(payoutDestinationSetState('createPayoutDestinationAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { payout_destination_config } = await apiRequest<{ payout_destination_config: ApiPayoutDestinationConfig }>('POST', requestAccountId, `${baseV1Url(requestAccountId)}/payout-destinations`, undefined, draft);
            dispatch(payoutDestinationSetState('createPayoutDestinationSuccess', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                payoutDestinations: {
                    ...state.payoutDestinations,
                    [requestAccountId]: {
                        ...state.payoutDestinations[requestAccountId],
                        [payout_destination_config.id]: transformApiConfigPayoutDestination(payout_destination_config),
                    },
                },
            })));
            dispatch(addSuccessMessageAction('notifications.create_payout_destination_success'));
            return payout_destination_config.id;
        } catch (err) {
            dispatch(payoutDestinationSetState('createPayoutDestinationFailure', state => ({
                lastError: err,
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addApiErrorMessageAction(err));
            return null;
        }
    };

    const updatePayoutDestination = async (requestAccountId: string, payoutDestinationConfigId: string, patch: Partial<Omit<PayoutDestinationConfigDraft, 'payout_destination_id'>>) => {
        dispatch(payoutDestinationSetState('updatePayoutDestinationAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { payout_destination_config } = await apiRequest<{ payout_destination_config: ApiPayoutDestinationConfig }>('PATCH', requestAccountId, `${baseV1Url(requestAccountId)}/payout-destinations/${payoutDestinationConfigId}`, undefined, patch);
            dispatch(payoutDestinationSetState('updatePayoutDestinationSucces', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                payoutDestinations: {
                    ...state.payoutDestinations,
                    [requestAccountId]: {
                        ...state.payoutDestinations[requestAccountId],
                        [payout_destination_config.id]: transformApiConfigPayoutDestination(payout_destination_config),
                    },
                },
            })));
            dispatch(addSuccessMessageAction('notifications.update_payout_destination_success'));
            return true;
        } catch (err) {
            dispatch(payoutDestinationSetState('updatePayoutDestinationFailure', state => ({
                lastError: err,
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addApiErrorMessageAction(err));
            return false;
        }
    };

    const updatePayoutDestinationV2 = async (requestAccountId: string, payoutDestinationConfigId: string, patch: Partial<Omit<PayoutDestinationConfigDraft, 'payout_destination_id'>>) => {
        dispatch(payoutDestinationSetState('updatePayoutDestinationAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));

        try {
            const { payout_destination_config } = await apiRequest<{ payout_destination_config: ApiPayoutDestinationConfig }>('PATCH', requestAccountId, `${baseV2Url(requestAccountId)}/payout-destinations/${payoutDestinationConfigId}`, undefined, patch);
            dispatch(payoutDestinationSetState('updatePayoutDestinationSucces', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                payoutDestinations: {
                    ...state.payoutDestinations,
                    [requestAccountId]: {
                        ...state.payoutDestinations[requestAccountId],
                        [payout_destination_config.id]: transformApiConfigPayoutDestination(payout_destination_config),
                    },
                },
            })));
            dispatch(addSuccessMessageAction('notifications.update_payout_destination_success'));
            return true;
        } catch (err) {
            dispatch(payoutDestinationSetState('updatePayoutDestinationFailure', state => ({
                lastError: err,
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addApiErrorMessageAction(err));
            return false;
        }
    };

    const createConfigBankAccountPayoutDestination = async (requestAccountId: string, data: Pick<ConfigBankAccountPayoutDestination, 'config_bank_account_id' | 'payout_destination_config_id' | 'is_default'>) => {
        dispatch(bankAccountPayoutDestinationSetState('createConfigBankAccountPayoutDestinationAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { bank_account_payout_destination } = await apiRequest<{ bank_account_payout_destination: ApiConfigBankAccountPayoutDestination }>('POST', requestAccountId, `${baseV1Url(requestAccountId)}/bank-account-payout-destinations`, undefined, data);
            dispatch(bankAccountPayoutDestinationSetState('createConfigBankAccountPayoutDestinationSuccess', state => ({
                bankAccountPayoutDestinations: {
                    ...state.bankAccountPayoutDestinations,
                    [requestAccountId]: {
                        ...state.bankAccountPayoutDestinations[requestAccountId],
                        [bank_account_payout_destination.id]: transformApiConfigBankAccountPayoutDestination(bank_account_payout_destination),
                    },
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addSuccessMessageAction('notifications.create_bank_account_payout_destination_success'));
            return true;
        } catch (err) {
            dispatch(bankAccountPayoutDestinationSetState('createConfigBankAccountPayoutDestinationFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
            return false;
        }
    };

    const getBankAccountPayoutDestinations = async (requestAccountId: string) => {
        dispatch(bankAccountPayoutDestinationSetState('getBankAccountPayoutDestinationsAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { bank_account_payout_destinations } = await apiRequest<{ bank_account_payout_destinations: ApiConfigBankAccountPayoutDestination[]}>('GET', requestAccountId, `${baseV1Url(requestAccountId)}/bank-account-payout-destinations`);
            dispatch(bankAccountPayoutDestinationSetState('getBankAccountPayoutDestinationsSuccess', state => ({
                bankAccountPayoutDestinations: {
                    ...state.bankAccountPayoutDestinations,
                    [requestAccountId]: {
                        ...state.bankAccountPayoutDestinations[requestAccountId],
                        ..._keyBy(bank_account_payout_destinations.map(transformApiConfigBankAccountPayoutDestination), 'id'),
                    },
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
        } catch (err) {
            dispatch(bankAccountPayoutDestinationSetState('getBankAccountPayoutDestinationsFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const updateBankAccountPayoutDestination = async (
        requestAccountId: string,
        baslId: string,
        patch: Partial<Pick<ConfigBankAccountPayoutDestination, 'is_default' | 'config_bank_account_id' | 'payout_destination_config_id'>>
    ) => {
        dispatch(bankAccountPayoutDestinationSetState('updateBankAccountPayoutDestinationAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { bank_account_payout_destination } = await apiRequest<{ bank_account_payout_destination: ApiConfigBankAccountPayoutDestination}>('PATCH', requestAccountId, `${baseV1Url(requestAccountId)}/bank-account-payout-destinations/${baslId}`,  undefined, patch);
            dispatch(bankAccountPayoutDestinationSetState('updateBankAccountPayoutDestinationSuccess', state => ({
                bankAccountPayoutDestinations: {
                    ...state.bankAccountPayoutDestinations,
                    [requestAccountId]: {
                        ...(state.bankAccountPayoutDestinations[requestAccountId] || {}),
                        [bank_account_payout_destination.id]: transformApiConfigBankAccountPayoutDestination(bank_account_payout_destination),
                    },
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addSuccessMessageAction('notifications.update_bank_account_payout_destination_success'));
            return true;
        } catch (err) {
            dispatch(bankAccountPayoutDestinationSetState('updateBankAccountPayoutDestinationFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: true,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
            return false;
        }
    };

    const deleteBankAccountPayoutDestination = async (requestAccountId: string, baslId: string) => {
        dispatch(bankAccountPayoutDestinationSetState('deleteBankAccountPayoutDestinationAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            await apiRequest('DELETE', requestAccountId, `${baseV1Url(requestAccountId)}/bank-account-payout-destinations/${baslId}`);
            dispatch(bankAccountPayoutDestinationSetState('deleteBankAccountPayoutDestinationSuccess', state => ({
                bankAccountPayoutDestinations: {
                    ...state.bankAccountPayoutDestinations,
                    [requestAccountId]: _omit(state.bankAccountPayoutDestinations[requestAccountId], baslId),
                },
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addSuccessMessageAction('notifications.delete_bank_account_payout_destination_success'));
        } catch (err) {
            dispatch(bankAccountPayoutDestinationSetState('deleteBankAccountPayoutDestinationFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                lastError: err,
            })));
            dispatch(addApiErrorMessageAction(err));
        }
    };

    const adminUpdatePayoutDestination = async (requestAccountId: string, payoutDestinationId: string, patch: Partial<PayoutDestinationConfig>) => {
        dispatch(payoutDestinationSetState('updatePayoutDestinationAttempt', state => ({
            ...state,
            loading: {
                ...state.loading,
                [requestAccountId]: true,
            },
        })));
        try {
            const { payout_destination_config } = await apiRequest('PATCH', requestAccountId, `${baseV1Url(requestAccountId)}/admin/merchants/${requestAccountId}/payout_destinations/${payoutDestinationId}/`, undefined, patch);

            dispatch(payoutDestinationSetState('updatePayoutDestinationSucces', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
                payoutDestinations: {
                    ...state.payoutDestinations,
                    [requestAccountId]: {
                        ...state.payoutDestinations[requestAccountId],
                        [payout_destination_config.id]: transformApiConfigPayoutDestination(payout_destination_config),
                    },
                },
            })));
            dispatch(addSuccessMessageAction('notifications.update_payout_destination_success'));
        } catch (err) {
            dispatch(payoutDestinationSetState('updatePayoutDestinationFailure', state => ({
                lastError: err,
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            dispatch(addApiErrorMessageAction(err));
            return false;
        }
    };


    return {
        getAccountConfig,
        updateAccountConfig,
        deleteAccountConfig,

        getConfigRfcs,
        getConfigRfc,
        createConfigRfc,
        updateConfigRfc,
        deleteConfigRfc,

        getBankAccounts,
        getBankAccount,
        updateBankAccount,
        deleteBankAccount,

        getPayoutDestinations,
        getPayoutDestination,
        createPayoutDestination,
        updatePayoutDestination,
        adminUpdatePayoutDestination,

        createConfigBankAccountPayoutDestination,
        getBankAccountPayoutDestinations,
        updateBankAccountPayoutDestination,
        deleteBankAccountPayoutDestination,

        getPayoutDestinationBalance,
        getPayoutDestinationTransfers,

        updatePayoutDestinationV2,
    };
};
