import React, { useEffect, useState } from 'react';
import { H2, P, Small } from '../../../../components/Typography';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { Dd, Dl, Dt } from '../../../../components/DescriptionList/Horizontal';
import { DateTime } from '../../../../components/Formatters';
import { WithTranslation } from 'react-i18next';
import { Account, CollectorConnection } from '../../../../types/management-auth';
import {
    ConnectionAside,
    ConnectionsStatus,
    ConnectionText,
    Details,
    Flex,
    PaymentConnection,
    ToggleDetails
} from './styledListItemComponents';
import { CheckoutConfig } from '../../../../types/checkout';
import { CheckoutConfigWrite } from '../../../../types/checkout/generated';
import { ActivePaymentOptions } from './ActivePaymentOptions';
import { PaymentOptionsDialog } from './PaymentOptionsDialog';
import styled from 'styled-components/macro';
import View from '../../../../components/View';
import { distances } from '../../../../styles/constants';

export interface CollectorConnectionListItemProps extends WithTranslation {
    openCollectorModal: (account: Account) => void;
    openCollectorCallbackModal: (type?: 'swap_agreement' | 'initial_connection_configuration') => void;
    collector: CollectorConnection | undefined;
    disabled: boolean;
    account: Account;
    accountId: string;
    checkoutConfig: CheckoutConfig | undefined;
    updateCheckoutConfig: (accountId: string, checkoutConfig: CheckoutConfigWrite) => Promise<void>;
    isUpdatedSuccessfully: boolean;
}

const availablePaymentOptions: CheckoutConfigWrite['configuration']['payment_options'] = [
    { type: 'collector.installment', currencies: ['NOK', 'SEK', 'EUR']},
    { type: 'collector.invoice', currencies: ['NOK', 'SEK', 'EUR']},
    { type: 'collector.invoice_b2b', currencies: ['NOK', 'SEK', 'EUR']},
];

type CollectorStore = {
    store_id: number;
    country: string;
    segment: 'B2C' | 'B2B';
    currency?: string;
}

const collectorStores = (checkoutConfig: CheckoutConfig): CollectorStore[] => {
    const collectorGateway = checkoutConfig.gateways?.collector;

    if (!collectorGateway) {
        return [];
    }
    const root = {
        store_id: collectorGateway.store_id,
        country: collectorGateway.country,
        segment: 'B2C',
    } as const;
    const other = (collectorGateway.stores || []).map(store => {
        return {
            store_id: store.store_id || 0,
            country: store.country || '',
            segment: store.segment || 'B2C',
            currency: store.currency,
        };
    });
    return [
        root,
        ...other,
    ];
};

type CollectorStoreDetailsProps = {
    collectorStore: CollectorStore;
}
const CollectorStoreDetails = ({ collectorStore }: CollectorStoreDetailsProps) => {
    return (
        <span>{Object.keys(collectorStore).map((key) => {
            const value = collectorStore[key as keyof CollectorStore];
            return (<span key={key}><b>{key}</b>: {value} </span>);
        })}
        </span>
    );
};

const CollectorConnectionListItem = (
    {
        t,
        collector,
        openCollectorModal,
        openCollectorCallbackModal,
        disabled,
        account,
        accountId,
        checkoutConfig,
        isUpdatedSuccessfully,
        updateCheckoutConfig,
    }: CollectorConnectionListItemProps
) => {
    const [collectorDetails, setCollectorDetails] = useState(false);
    const [showPaymentOptionsDialog, setShowPaymentOptionsDialog] = useState(false);

    useEffect(() => {
        if (isUpdatedSuccessfully) {
            setShowPaymentOptionsDialog(false);
        }
    }, [isUpdatedSuccessfully]);

    return (
        <PaymentConnection disabled={disabled}>
            <View alignItems="flex-start" gap={distances.small}>
                <H2>{t(`settings.payment_connections.collector.name`)}</H2>
                {collector && collector.status && (
                    <ConnectionsStatus status={collector.status}>
                        {t(`settings.payment_connections.status.${collector.status}`)}
                    </ConnectionsStatus>
                )}
            </View>
            <Flex>
                <ConnectionText>
                    <P>{t(`settings.payment_connections.collector.description`)}</P>
                </ConnectionText>
                <ConnectionAside>
                    {!collector && (
                        <Button
                            disabled={disabled}
                            onClick={() => openCollectorModal(account)}
                            className="stretch"
                        >
                            {t(`settings.payment_connections.collector.call_to_action`)}
                        </Button>
                    )}
                    {collector && !collector?.signup?.callback_at && (
                        <Button onClick={() => openCollectorModal(account)} className="alt">
                            {t(`settings.payment_connections.collector.complete_callback`)}
                        </Button>
                    )}
                </ConnectionAside>
            </Flex>
            {collector && (
                <>
                    <ToggleDetails onClick={() => setCollectorDetails(!collectorDetails)}>
                        {collectorDetails
                            ? t('settings.payment_connections.collapse_details')
                            : t('settings.payment_connections.expand_details')}
                    </ToggleDetails>
                    <Details className={collectorDetails ? 'open' : 'closed'}>
                        {collector.signup?.created_at && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.created_at')}</Dt>
                            <Dd>
                                <DateTime isoString={collector.signup.created_at} />
                            </Dd>
                        </Dl>}
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.callback_at')}</Dt>
                            <Dd>
                                {collector.signup?.callback_at ? (
                                    <DateTime isoString={collector.signup.callback_at} />
                                ) : (
                                    <em>{t('settings.payment_connections.fields.no_callback_yet')}</em>
                                )}
                            </Dd>
                        </Dl>

                        {checkoutConfig && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.collector_gateway')}</Dt>
                            <Dd>
                                <UnstyledList>
                                    {collectorStores(checkoutConfig).map(store => {
                                        return (
                                            <li key={store.segment + '-' + store.currency + '-' + store.store_id}><CollectorStoreDetails collectorStore={store} /></li>
                                        );
                                    })
                                    }
                                </UnstyledList>
                            </Dd>
                        </Dl>}
                        {checkoutConfig &&
                            <ActivePaymentOptions checkoutConfig={checkoutConfig} paymentType="collector" />}
                        {checkoutConfig && <ButtonGroup>
                            <Button
                                type="button"
                                className="alt"
                                onClick={() => setShowPaymentOptionsDialog(true)}>
                                {t('settings.payment_connections.edit_payment_options_button')}
                            </Button>
                            <Button onClick={() => openCollectorCallbackModal('swap_agreement')} className="alt">
                                {t(`settings.payment_connections.collector.swap_agreement`)}
                            </Button>
                            <PaymentOptionsDialog
                                availablePaymentOptions={availablePaymentOptions}
                                isDialogOpen={showPaymentOptionsDialog}
                                handleCloseDialog={() => setShowPaymentOptionsDialog(false)}
                                handleSavePaymentOptions={(newCheckoutConfiguration) => updateCheckoutConfig(accountId, newCheckoutConfiguration)}
                                checkoutConfig={checkoutConfig}
                                message={t('settings.payment_connections.edit_payment_options.collector.caution')}
                            />
                        </ButtonGroup>
                        }
                    </Details>
                </>
            )}
        </PaymentConnection>
    );
};

const UnstyledList = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
`;


export default CollectorConnectionListItem;
