import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card';
import Page from '../../../components/Page';
import { getCurrentLanguage } from '../../../i18n';
import { checkAccountReadyForProduction } from '../../account/helpers';
import useAccount from '../../account/hooks/useAccount';
import FinishAccountInfobox from '../../components/FinishAccountInfobox';
import { lngToCurrency } from '../helpers';
import DailyPayoutsBox from './DailyPayoutsBox';
import SplitPaymentBox from './SplitPaymentBox';
import { Wrapper } from './elements';

export default function AdditionalServicesPage() {
    const { t, i18n } = useTranslation();
    const lng = getCurrentLanguage(i18n);
    const currency = lngToCurrency(lng);
    const { account } = useAccount();
    const showTestModeBox = useMemo(() => account && !checkAccountReadyForProduction(account), [account]);
    if (showTestModeBox) {
        return (
            <Page
                title={
                    t('settings.additional_services.title')
                }
            >
                <Card>
                    <FinishAccountInfobox />
                </Card>
            </Page>
        );
    }

    return (
        <Page
            title={
                t('settings.additional_services.title')
            }
        >
            <Wrapper>
                <DailyPayoutsBox />
                <SplitPaymentBox />
            </Wrapper>
        </Page>
    );
}
